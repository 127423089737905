import type { ServiceId } from "@/src/lib/townhouseApiClient";
import {
  type BookingState,
  type BookingStateService,
  BookingStoreNonExistentServiceError,
} from "@/src/stores/bookingStore";

export class BookingStoreMapNameError extends Error {}

export const mapToNames = <Id extends string, Name>(ids: Set<Id>, items: Map<Id, { name: Name }>): Name[] =>
  Array.from(ids).map((id) => {
    const info = items.get(id);
    if (!info) {
      throw new BookingStoreMapNameError(`Failed to find a name associated with item ID ${id}`);
    }
    return info.name;
  });

export const serviceToSummary = (
  servicesByLocation: NonNullable<BookingState["servicesByLocation"]>,
  serviceId: ServiceId,
  service: BookingStateService,
) => {
  const serviceInfo = servicesByLocation.get(serviceId);

  if (!serviceInfo) {
    throw new BookingStoreNonExistentServiceError(`Failed to find service by location with ID ${serviceId}`);
  }

  return {
    serviceName: serviceInfo.name,
    addOnNames: mapToNames(service.addOnIds, serviceInfo.addOns),
    removalNames: mapToNames(service.removalIds, serviceInfo.removals),
  };
};

<script setup lang="ts">
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import Checkbox from "@/src/components/Checkbox.vue";
import Icon from "@/src/components/Icon.vue";
import InputDate from "@/src/components/InputDate.vue";
import InputEmail from "@/src/components/InputEmail.vue";
import InputText from "@/src/components/InputText.vue";
import NavigationTop from "@/src/components/NavigationTop.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBodyError from "@/src/components/TextBodyError.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { regionFromOrigin } from "@/src/config/env";
import { validateAndFormatDateOfBirth, validateAndFormatPhone, validateName } from "@/src/lib/formValidationHelpers";
import { useUserStore } from "@/src/stores/userStore";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();
const userStore = useUserStore();
const route = useRoute();
const { user } = storeToRefs(userStore);
const router = useRouter();
const hasUpdated = ref(false);

// State variables
const firstName = ref<string>(user.value.firstName || "");
const lastName = ref<string>(user.value.lastName || "");
const phone = ref<string>(user.value.phoneNumber?.toString() || "");
const dateOfBirthDay = ref<string>(user.value.dateOfBirthUtc?.day.toString() || "");
const dateOfBirthMonth = ref<string>(user.value.dateOfBirthUtc?.month.toString() || "");
const dateOfBirthYear = ref<string>(user.value.dateOfBirthUtc?.year.toString() || "");
const marketingEmailOptIn = ref<boolean | null>(user.value.marketingEmailOptIn);
const marketingSmsOptIn = ref<boolean | null>(user.value.marketingSmsOptIn);

// Update component refs to what is stored in state - it is possible for refs to be
// initialised with null values before

// Error state variables
const formError = ref<string | undefined>();
const firstNameError = ref<string | undefined>();
const lastNameError = ref<string | undefined>();
const phoneError = ref<string | undefined>();
const dateOfBirthError = ref<string | undefined>();
const dateOfBirthErrorInDay = ref(false);
const dateOfBirthErrorInMonth = ref(false);
const dateOfBirthErrorInYear = ref(false);

const currentRegion = regionFromOrigin();

const handleBack = () => {
  if (route.query && typeof route.query["returnTo"] === "string") {
    return router.push({ path: "/account", query: { returnTo: route.query["returnTo"] } });
  }

  router.push("/account");
};

const updateAccount = async () => {
  hasUpdated.value = false;

  // Field validations
  validateName({
    name: firstName.value,
    errorRef: firstNameError,
    errorMessage: t("createAccount.error.firstNameInvalid"),
  });
  validateName({
    name: lastName.value,
    errorRef: lastNameError,
    errorMessage: t("createAccount.error.lastNameInvalid"),
  });
  const phoneParsed = validateAndFormatPhone({
    phoneNumber: phone.value,
    region: currentRegion,
    errorRef: phoneError,
    errorMessage: t("createAccount.error.phoneInvalid"),
  });
  const dateOfBirthParsed = validateAndFormatDateOfBirth({
    dateOfBirth: {
      day: dateOfBirthDay.value,
      month: dateOfBirthMonth.value,
      year: dateOfBirthYear.value,
    },
    errorRefs: {
      dateErrorRef: dateOfBirthError,
      errorInDayRef: dateOfBirthErrorInDay,
      errorInMonthRef: dateOfBirthErrorInMonth,
      errorInYearRef: dateOfBirthErrorInYear,
    },
    errorMessages: {
      invalidError: t("createAccount.error.dateOfBirthInvalid"),
      belowAgeError: t("createAccount.error.dateOfBirthUnder16"),
    },
  });

  if (
    firstNameError.value ||
    lastNameError.value ||
    phoneError.value ||
    !phoneParsed ||
    dateOfBirthError.value ||
    !dateOfBirthParsed
  ) {
    return;
  }

  try {
    await userStore.updateUser({
      firstName: firstName.value,
      lastName: lastName.value,
      phoneNumber: phoneParsed,
      dateOfBirthUtc: dateOfBirthParsed,
      marketingEmailOptIn: marketingEmailOptIn.value,
      marketingSmsOptIn: marketingSmsOptIn.value,
    });
  } catch (_) {
    formError.value = t("account.details.error");
    return;
  }

  formError.value = undefined;
  hasUpdated.value = true;
};
</script>

<template>
  <div class="w-full max-w-[1140px] flex flex-col justify-center items-center relative">
    <TextHeader1 class="p-10">{{ t("account.details.header") }}</TextHeader1>
    <NavigationTop @back="handleBack" />
    <div class="w-full max-w-[600px] flex flex-col gap-10 p-10">
      <InputText v-model="firstName" :error="firstNameError" :label="t('account.details.firstName')" autocomplete="given-name" required />
      <InputText v-model="lastName" :error="lastNameError" :label="t('account.details.lastName')" autocomplete="family-name" required/>
      <InputEmail v-model="user.email" :label="t('account.details.email')" disabled required/>
      <InputText v-model="phone" :label="t('account.details.phone')" required :error="phoneError" />
      <InputDate v-model:day="dateOfBirthDay"
        v-model:month="dateOfBirthMonth"
        v-model:year="dateOfBirthYear"
        is-date-of-birth
        :label="t('account.details.dateOfBirth')"
        required
        :error="dateOfBirthError"
        :error-in-day="dateOfBirthErrorInDay"
        :error-in-month="dateOfBirthErrorInMonth"
        :error-in-year="dateOfBirthErrorInYear" />
      <TextBody1 class="w-full">{{ t("account.details.marketing.description") }}</TextBody1>
      <div class="flex gap-10">
        <Checkbox v-model="marketingEmailOptIn" :has-error="false">
          <TextBody1>
            {{ t("account.details.marketing.email") }}
          </TextBody1>
        </Checkbox>
        <Checkbox v-model="marketingSmsOptIn" :has-error="false">
          <TextBody1>
            {{ t("account.details.marketing.sms") }}
          </TextBody1>
        </Checkbox>
      </div>
      <div class="flex flex-col gap-10 items-center justify-center">
        <ButtonPrimary @click="updateAccount" class="w-[200px]">Update</ButtonPrimary>
        <div v-if="hasUpdated && !formError" class="flex items-center gap-5">
          <Icon class="text-complete-tick" :icon="faCircleCheck" size="2xl"/>
          <TextBody1>{{ t("account.details.success") }}</TextBody1>
        </div>
        <div v-else-if="formError">
          <TextBodyError>
            <TextBody1>{{ formError }}</TextBody1>
          </TextBodyError>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();

onMounted(() => {
  // @ts-expect-error
  window.storeLocatorListLocationsHandler = () => {
    const links = document.querySelectorAll(".storelocator-btn");

    for (const link of Array.from(links)) {
      link.setAttribute("href", "#");
      link.addEventListener("click", (e) => {
        e.preventDefault();

        // FIXME: Remove this hack when we're ready
        router.push({
          path: "/locations/GB-LON-GPS",
        });

        return false;
      });
    }
  };
});
</script>

<template>
  <h2 class="flex justify-center my-5">
    <TextHeader1 class="text-center">{{ t('locations.header') }}</TextHeader1>
  </h2>
  <div class="px-5 storelocatorwidget">
    <div id="storelocatorwidget">
      <p>
        <a href="https://www.storelocatorwidgets.com"
          >Loading Store Locator Software</a
        >...
      </p>
    </div>
  </div>
  <component
    is="script"
    type="text/javascript"
    id="mapboxscript"
    src="//cdn.storelocatorwidgets.com/widget/mapbox-gl.js"
  ></component>
  <component
    is="script"
    type="text/javascript"
    id="storelocatorscript"
    data-uid="1607da525f961b5c78c050e22338ed9a"
    data-platform="MapTiler"
    src="//cdn.storelocatorwidgets.com/widget/widget.js"
  ></component>
</template>

<script setup lang="ts">
import TextBodyError from "@/src/components/TextBodyError.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";

import type { InputHTMLAttributes } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const { spellcheck = true } = defineProps<{
  label: string;
  required?: boolean;
  spellcheck?: boolean;
  autocomplete?: InputHTMLAttributes["autocomplete"];
  error?: string;
  disabled?: boolean;
  placeholder?: string;
}>();
const model = defineModel();
</script>

<template>
  <div>
    <label :class="`${disabled ? 'text-input-disabled' : ''}`">
      <TextHeader3 class="block">
        {{ label }}{{ !required ? ` ${t("input.optionalLabel")}` : "" }}
      </TextHeader3>
      <input
      :class="`w-full rounded-none border-2 ${ error ? 'border-error' : 'border-input-border' } text-base p-5 focus:outline-none focus:border-2 focus:border-input-focus-outline ${disabled ? 'border-input-disabled placeholder:text-input-disabled cursor-not-allowed' : ''}`"
      v-model="model"
      :spellcheck="spellcheck"
      :autocomplete="autocomplete"
      :required="required"
      :placeholder="placeholder"
      :disabled="disabled"
      :aria-invalid="Boolean(error)"
      :aria-errormessage="error"
      />
    </label>
    <TextBodyError v-if="error" class="block text-xs mt-1">
      {{ error }}
    </TextBodyError>
  </div>
</template>

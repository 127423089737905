export type EnvName = "gb-production" | "us-production" | "gb-staging" | "us-staging" | "development";
export type Region = "GB" | "US";

export const envNameFromOrigin = (): EnvName => {
  switch (location.origin) {
    case "https://booking.townhouse.co.uk":
      return "gb-production";
    case "https://booking.townhousebeauty.com":
      return "us-production";
    case "https://booking.staging.townhouse.co.uk":
      return "gb-staging";
    case "https://booking.staging.townhousebeauty.com":
      return "us-staging";
    default: {
      if (location.origin.endsWith("townhouse.vercel.app")) {
        return "gb-staging";
      }

      return "development";
    }
  }
};

export const regionFromOrigin = (): Region => {
  const envName = envNameFromOrigin();

  // biome-ignore lint/style/useDefaultSwitchClause: It's typesafe to not have one
  switch (envName) {
    case "gb-production":
      return "GB";
    case "us-production":
      return "US";
    case "gb-staging":
      return "GB";
    case "us-staging":
      return "US";
    case "development":
      return "GB";
  }
};

export const apiOriginFromOrigin = (): string => {
  switch (envNameFromOrigin()) {
    case "gb-production":
      return import.meta.env.VITE_TOWNHOUSE_API_ORIGIN_GB_PRODUCTION;
    case "us-production":
      return import.meta.env.VITE_TOWNHOUSE_API_ORIGIN_US_PRODUCTION;
    case "gb-staging":
      return import.meta.env.VITE_TOWNHOUSE_API_ORIGIN_GB_STAGING;
    case "us-staging":
      return import.meta.env.VITE_TOWNHOUSE_API_ORIGIN_US_STAGING;
    default:
      return import.meta.env.VITE_TOWNHOUSE_API_ORIGIN;
  }
};

<script setup lang="ts">
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import ButtonPlaintext from "@/src/components/ButtonPlaintext.vue";
import Calendar from "@/src/components/Calendar.vue";
import Icon from "@/src/components/Icon.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader2 from "@/src/components/TextHeader2.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import type { LocationId } from "@/src/lib/townhouseApiClient";
import PopupAuth from "@/src/popups/PopupAuth.vue";
import PopupSelectEmployee from "@/src/popups/PopupSelectEmployee.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { useBookingStore } from "@/src/stores/bookingStore";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { DateTime, Duration } from "luxon";
import { storeToRefs } from "pinia";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const authStore = useAuthStore();
const bookingStore = useBookingStore();
const router = useRouter();

defineProps<{
  locationId: LocationId;
}>();

const { isLoggedIn } = storeToRefs(authStore);
const { bookingDateUtc, bookingDateTimeUtc, availableDateTimesUtc, employeeChosen } = storeToRefs(bookingStore);

const selectingEmployee = ref<boolean>(false);
const showMoreTimesClicked = ref<boolean>(false);

const minDate = DateTime.now();
const maxDate = minDate.plus({ months: 3 });

const initBookingDateUtc = (isLoggedIn: boolean) => {
  if (!isLoggedIn || bookingDateUtc.value) {
    return;
  }

  bookingStore.setBookingDateUtc(DateTime.now().toUTC());
};

onMounted(async () => {
  initBookingDateUtc(isLoggedIn.value);

  // Set up an interval to refresh any available times every 5 minutes
  const interval = setInterval(
    async () => {
      if (bookingStore.availableDateTimesUtc) {
        await bookingStore.refetchAvailableTimes();
      }
    },
    Duration.fromObject({ minutes: 5 }).as("milliseconds"),
  );

  // Clear the interval when the component is unmounted
  onUnmounted(() => {
    clearInterval(interval);
  });
});

watch(isLoggedIn, (newIsLoggedIn) => initBookingDateUtc(newIsLoggedIn));

const handleLoginCancelled = () => {
  router.back();
};

const handleSelectEmployeeClicked = async () => {
  await bookingStore.fetchAvailableEmployees();
  selectingEmployee.value = true;
};

const handleSelectEmployeeCancelled = () => {
  selectingEmployee.value = false;
};

const onTimeChosen = (newBookingDateTimeUtc: DateTime | string) => {
  // It's always a date/time, we just can't prove it to Typescript
  bookingStore.setBookingDateTimeUtc(newBookingDateTimeUtc as DateTime);
};

watch(bookingDateUtc, async (newBookingDateUtc) => {
  if (!newBookingDateUtc) {
    return;
  }

  showMoreTimesClicked.value = false;
  bookingDateTimeUtc.value = null;
  availableDateTimesUtc.value = null;
  await bookingStore.fetchAvailableTimes();
});

const showMoreTimes = computed(() => {
  if (!availableDateTimesUtc.value) {
    return false;
  }

  if (showMoreTimesClicked.value) {
    return true;
  }

  const index = _.findIndex(availableDateTimesUtc.value, (availableDateTimeUtc) =>
    Boolean(bookingDateTimeUtc.value?.equals(availableDateTimeUtc)),
  );
  return index > 6;
});

const visibleAvailableDateTimesUtc = computed(() => {
  if (availableDateTimesUtc.value === null) {
    return null;
  }

  if (showMoreTimes.value) {
    return availableDateTimesUtc.value;
  }

  return availableDateTimesUtc.value.slice(0, 6);
});
</script>

<template>
  <PopupAuth v-if="!isLoggedIn" :location-id="locationId" @cancelled="handleLoginCancelled" />
  <PopupSelectEmployee v-if="isLoggedIn && selectingEmployee" @cancelled="handleSelectEmployeeCancelled" @employee-chosen-next-click="handleSelectEmployeeCancelled" />
  <div class="flex flex-col gap-5 px-5">
    <h2 class="my-5 flex justify-center text-center">
      <TextHeader1 class="text-center">{{ t('dateTime.header') }}</TextHeader1>
    </h2>
    <div class="flex justify-center md:mb-10">
      <ButtonInScreenTertiary class="w-full max-w-[600px]" @click="handleSelectEmployeeClicked">
        <div class="flex gap-x-5 justify-between items-center">
          <Icon :icon="faUser" size="1x" />
          <TextHeader3 class="w-full text-left">
            {{ employeeChosen ? t('dateTime.nailArtist.chosen', { name: employeeChosen.firstName }) : t('dateTime.nailArtist.any') }}
          </TextHeader3>
          <Icon :icon="faAngleRight" size="lg" />
        </div>
      </ButtonInScreenTertiary>
    </div>
    <div class="flex flex-col md:flex-row gap-10">
      <Calendar class="w-full" v-model="bookingDateUtc" :min-date="minDate" :max-date="maxDate" />
      <div class="w-full flex flex-col gap-5 md:border-available-times-border md:border md:p-5">
        <h3 class="flex justify-center">
          <TextHeader2>{{ t('dateTime.allTimes') }}</TextHeader2>
        </h3>
        <div v-if="availableDateTimesUtc && availableDateTimesUtc.length > 0">
          <div role="radiogroup" class="w-full flex flex-wrap gap-5">
            <div v-for="availableDateTimeUtc in visibleAvailableDateTimesUtc" class="w-[calc(33.333%-0.833rem)] lg:w-[calc(20%-1rem)] shrink-0 grow-0">
              <button
              :class="`block w-full p-5 ${bookingDateTimeUtc?.equals(availableDateTimeUtc) ? 'bg-button-grid-selected' : 'bg-button-grid-unselected'}`"
              @click="onTimeChosen(availableDateTimeUtc)"
              role="radio"
              >
                <TextBody1>
                  {{ availableDateTimeUtc.toLocal().toFormat(t('dateTime.timeFormat')) }}
                </TextBody1>
              </button>
            </div>
          </div>
          <ButtonPlaintext v-if="!showMoreTimes && (availableDateTimesUtc && availableDateTimesUtc.length > 6)" class="mt-5" @click="showMoreTimesClicked = true">
            <TextHeader3>{{ t('dateTime.showMoreTimes') }}</TextHeader3>
          </ButtonPlaintext>
        </div>
        <div v-else-if="visibleAvailableDateTimesUtc && visibleAvailableDateTimesUtc.length === 0" class="w-full h-full flex items-center justify-center">
            <TextBody1>{{ t('dateTime.noTimesLeft') }}</TextBody1>
          </div>
        <div v-else class="w-full h-full flex items-center justify-center">
          <TextBody1>Loading...</TextBody1>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import ClosePopup from "@/src/components/ClosePopup.vue";
import Icon from "@/src/components/Icon.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

const handleClosePopupClick = () => {
  if (route.query && typeof route.query["returnTo"] === "string") {
    return router.push(route.query["returnTo"]);
  }

  router.push("/");
};

const handleAccountClick = () => {
  if (route.query && typeof route.query["returnTo"] === "string") {
    return router.push({ path: "/account/details", query: { returnTo: route.query["returnTo"] } });
  }
  router.push("/account/details");
};

const handleAppointmentsClick = () => {
  return;
};

const handleRewardsClick = () => {
  if (route.query && typeof route.query["returnTo"] === "string") {
    return router.push({ path: "/account/loyalty_points", query: { returnTo: route.query["returnTo"] } });
  }
  router.push("/account/loyalty_points");
};

const handleGiftCardsClick = () => {
  return;
};

const handlePaymentAccountsClick = () => {
  if (route.query && typeof route.query["returnTo"] === "string") {
    return router.push({ path: "/account/payment_accounts", query: { returnTo: route.query["returnTo"] } });
  }
  router.push({ path: "/account/payment_accounts" });
};

const handleLogOutClick = () => {
  authStore.logout();
  return router.push("/");
};
</script>

<template>
  <div class="w-full max-w-[1140px] p-10 flex flex-col justify-center items-center gap-5 relative">
    <ClosePopup @click="handleClosePopupClick"/>
    <TextHeader1 class="mb-10">{{ t("account.header") }}</TextHeader1>
    <ButtonInScreenTertiary @click="handleAccountClick">
      <div class="flex justify-between items-center px-5">
        <TextBody1>{{ t("account.details.label") }}</TextBody1>
        <Icon :icon="faChevronRight" size="1x"></Icon>
      </div>
    </ButtonInScreenTertiary>
    <ButtonInScreenTertiary @click="handleAppointmentsClick">
      <div class="flex justify-between items-center px-5">
        <TextBody1>{{ t("account.appointments.label") }}</TextBody1>
        <Icon :icon="faChevronRight" size="1x"></Icon>
      </div>
    </ButtonInScreenTertiary>
    <ButtonInScreenTertiary @click="handleRewardsClick">
      <div class="flex justify-between items-center px-5">
        <TextBody1>{{ t("account.rewards.label") }}</TextBody1>
        <Icon :icon="faChevronRight" size="1x"></Icon>
      </div>
    </ButtonInScreenTertiary>
    <ButtonInScreenTertiary @click="handleGiftCardsClick">
      <div class="flex justify-between items-center px-5">
        <TextBody1>{{ t("account.giftCards.label") }}</TextBody1>
        <Icon :icon="faChevronRight" size="1x"></Icon>
      </div>
    </ButtonInScreenTertiary>
    <ButtonInScreenTertiary @click="handlePaymentAccountsClick">
      <div class="flex justify-between items-center px-5">
        <TextBody1>{{ t("account.paymentAccounts.label") }}</TextBody1>
        <Icon :icon="faChevronRight" size="1x"></Icon>
      </div>
    </ButtonInScreenTertiary>
    <ButtonInScreenTertiary @click="handleLogOutClick">
      <div class="flex justify-between items-center px-5">
        <TextBody1>{{ t("account.logOut.label") }}</TextBody1>
        <Icon :icon="faChevronRight" size="1x"></Icon>
      </div>
    </ButtonInScreenTertiary>
  </div>
</template>

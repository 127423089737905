<script setup lang="ts">
import ButtonPlaintext from "@/src/components/ButtonPlaintext.vue";
import Icon from "@/src/components/Icon.vue";
import TextHeader2 from "@/src/components/TextHeader2.vue";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useI18n } from "vue-i18n";

defineEmits<{
  back: [];
}>();

const { t } = useI18n();
</script>

<template>
  <div class="w-full bg-button-disabled p-5">
    <!-- FIXME: Remove this wrapper once button allows width-auto -->
    <div class="inline-block">
      <ButtonPlaintext @click="$emit('back')" class="flex justify-start items-center cursor-default">
        <TextHeader2  class="cursor-pointer flex items-center gap-x-2">
          <Icon :icon="faAngleLeft" size="sm" />
          <span>{{ t("button.back") }}</span>
        </TextHeader2>
      </ButtonPlaintext>
    </div>
  </div>
</template>
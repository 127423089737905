<script setup lang="ts">
import ButtonPlaintext from "@/src/components/ButtonPlaintext.vue";
import Icon from "@/src/components/Icon.vue";
import TextBody3 from "@/src/components/TextBody3.vue";
import TextBody4 from "@/src/components/TextBody4.vue";
import TextHeader2 from "@/src/components/TextHeader2.vue";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useI18n } from "vue-i18n";

defineProps<{
  image: string;
  header: string;
  bodyText: string;
  duration: number;
  priceCents: number;
}>();

const { t, n } = useI18n();

const isSelected = defineModel<boolean>();

const handleServiceSelected = () => {
  isSelected.value = !isSelected.value;
};

defineEmits<{
  learnMore: [];
}>();
</script>

<template>
  <div class="flex">
    <div class="w-0 lg:w-[150px] lg:h-[150px]">
      <img :src="image" alt="Service image" class="w-full h-full"/>
    </div>
    <div
      :class="`flex-col justify-between w-full lg:w-[calc(100%-150px)] p-5 pr-10
      ${isSelected ? 'bg-button-binary-selected' : 'bg-button-binary-unselected'}`"
      @click="handleServiceSelected">
      <div>
        <TextHeader2 class="line-clamp-1">{{ header }}</TextHeader2>
      </div>
      <div class="py-5 min-h-32">
        <TextBody3 v-if="isSelected" class="line-clamp-3">{{ bodyText }}</TextBody3>
        <TextBody4 v-else class="line-clamp-3">{{ bodyText }}</TextBody4>
      </div>
      <div class="flex justify-between">
        <div>
          <ButtonPlaintext @click="$emit('learnMore')">
            <TextBody3>
              {{ t('serviceCard.learnMore')}} <Icon :icon="faAngleRight" size="sm"></Icon>
            </TextBody3>
          </ButtonPlaintext>
        </div>
        <div class="space-x-5">
          <TextBody3>{{ duration }} Mins</TextBody3>
          <TextBody3>{{ n(priceCents / 100, 'currency') }}</TextBody3>
        </div>
      </div>
    </div>
  </div>
</template>

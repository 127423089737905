<script setup lang="ts">
import ButtonPlaintext from "@/src/components/ButtonPlaintext.vue";
import Icon from "@/src/components/Icon.vue";
import TextBody3 from "@/src/components/TextBody3.vue";
import TextHeader2 from "@/src/components/TextHeader2.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useI18n } from "vue-i18n";

const { t, n } = useI18n();

defineProps<{
  pointsBalance: number;
  pointsValueCents: number;
}>();

defineEmits<{
  back: [];
}>();
</script>

<template>
  <div class="bg-button-disabled p-5">
    <ButtonPlaintext class="flex justify-start items-center cursor-default ">
      <TextHeader2 @click="$emit('back')" class="cursor-pointer flex items-center gap-x-2"><Icon :icon="faAngleLeft" size="sm"></Icon>{{ t("button.back") }}</TextHeader2>
    </ButtonPlaintext>
  </div>
  <div class="p-5">
    <div class="w-full flex flex-col gap-5 border-rewards-info-border border p-5 mb-5">
      <h3 class="flex justify-between">
        <TextHeader3>{{ t("rewards.howItWorks") }}</TextHeader3>
      </h3>
      <TextBody3 class="space-y-5">
        <div>
          {{ t("rewards.descriptionOfPoints") }}
        </div>
        <div>
          {{ t("rewards.descriptionOfRedemption") }}
        </div>
      </TextBody3>
    </div>
    <div class="w-full flex flex-col gap-5 border-rewards-info-border border p-5 mb-5">
      <h3 class="flex justify-between">
        <TextHeader3>{{ t("rewards.balance") }}</TextHeader3>
      </h3>
      <TextBody3 class="flex justify-between">
        <div>
          {{ t("rewards.currentBalance") }}:
        </div>
        <div>
          {{ t('rewards.points', { balance: pointsBalance }) }}
        </div>
      </TextBody3>
      <TextBody3 class="flex justify-between">
        <div>
          {{ t("rewards.redemptionValue") }}:
        </div>
        <div>
          {{  n(pointsValueCents/100, 'currency')  }}
        </div>
      </TextBody3>
    </div>
    <div class="w-full flex flex-col gap-5 border-rewards-info-border border p-5">
      <h3 class="flex justify-between">
        <TextHeader3>{{ t("rewards.howToRedeem") }}</TextHeader3>
      </h3>
      <div>
        <TextBody3>
          {{ t("rewards.redemptionInstructions") }}
        </TextBody3>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BookingSummary from "@/src/components/BookingSummary.vue";
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import Icon from "@/src/components/Icon.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import { useBookingStore } from "@/src/stores/bookingStore";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { computed, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { n, t } = useI18n();
const bookingStore = useBookingStore();
const router = useRouter();

const bookingSummary = computed(() => {
  return bookingStore.createBookingSummary();
});

const bookingTotalPriceCents = computed(() => {
  return bookingStore.calculateTotalBookingPriceCents();
});

onUnmounted(() => {
  bookingStore.$reset();
});

const handleBookAgain = () => {
  router.push("/");
};
</script>

<template>
  <h2 class="flex justify-center text-center my-5">
    <TextHeader1 class="text-center">{{ t('complete.header') }}</TextHeader1>
  </h2>
  <p class="flex justify-center text-center mb-10">
    <TextBody1 class="text-center">{{ t('complete.subHeader') }}</TextBody1>
  </p>
  <div class="flex justify-center mb-10">
    <Icon class="text-complete-tick" :icon="faCircleCheck" size="6x" />
  </div>
  <SectionDivider />
  <div class="m-5">
    <BookingSummary
      :edit-mode="false"
      :location="bookingSummary.locationName"
      :services="bookingSummary.serviceSummariesByGuest"
      :booking-date-time-utc="bookingSummary.bookingDateTimeUtc"
    />
    <div class="mt-10">
      <TextHeader3>{{ t("confirm.total.header") }}</TextHeader3>
      <div class="flex justify-between items-center mb-10">
        <TextBody1>{{ t("confirm.total.label") }}</TextBody1>
        <TextBody1>{{ n(bookingTotalPriceCents / 100, 'currency') }}</TextBody1>
      </div>
    </div>
  </div>
  <SectionDivider />
  <div class="flex justify-center mt-10">
    <div class="w-30">
      <ButtonPrimary @click="handleBookAgain">
        {{ t('complete.bookAgain') }}
      </ButtonPrimary>
    </div>
  </div>
</template>

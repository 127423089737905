<script setup lang="ts">
import ServiceCardImage from "@/src/assets/service-card-placeholder.png";
import ServiceInfo from "@/src/components/ServiceInfo.vue";
import type { AddOnId, LocationId, ServiceId } from "@/src/lib/townhouseApiClient";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import _ from "lodash";
import { computed } from "vue";
import { useRouter } from "vue-router";

const bookingStore = useBookingStore();
const router = useRouter();

const props = defineProps<{
  locationId: LocationId;
  guestNumber: GuestNumber;
  serviceId: ServiceId;
  addOnId: AddOnId;
}>();

const addOnInfo = computed(() => {
  return bookingStore.servicesByLocation?.get(props.serviceId)?.addOns.get(props.addOnId);
});

const handleBack = () => {
  router.back();
};
</script>

<template>
  <ServiceInfo v-if="addOnInfo" @back="handleBack"
    :image="ServiceCardImage"
    :serviceName="addOnInfo.name"
    :textBody="addOnInfo.longDescription"
    :durationMins="addOnInfo.typicalDurationMins"
    :priceCents="addOnInfo.priceCents" />
</template>

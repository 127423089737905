<script setup lang="ts">
import ServiceCardImage from "@/src/assets/service-card-placeholder.png";
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import Icon from "@/src/components/Icon.vue";
import ServiceCard from "@/src/components/ServiceCard.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import type { LocationId, ServiceId } from "@/src/lib/townhouseApiClient";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const bookingStore = useBookingStore();
const router = useRouter();

const props = defineProps<{
  locationId: LocationId;
}>();

const services = computed(() => {
  return bookingStore.servicesByLocationGroupedByType["groupPackage"];
});

const numberOfGuests = computed(() => {
  return bookingStore.numberOfGuests as GuestNumber;
});

const guestsHaveService = (serviceId: ServiceId): boolean => {
  return Boolean(bookingStore.servicesByGuest[0].has(serviceId));
};

const handleServiceToggled = (serviceId: ServiceId, newValue: boolean) => {
  for (let guest = 1; guest <= numberOfGuests.value; guest++) {
    bookingStore.removeAllServicesFromGuest(guest as GuestNumber);
  }

  if (!newValue) {
    return;
  }

  for (let guest = 1; guest <= numberOfGuests.value; guest++) {
    bookingStore.addServiceToGuest(guest as GuestNumber, serviceId);
  }
};

const handleChooseIndividuallyClicked = () => {
  if (bookingStore.isGroupPackageChosen()) {
    for (let guest = 1; guest <= numberOfGuests.value; guest++) {
      bookingStore.removeAllServicesFromGuest(guest as GuestNumber);
    }
  }
  return router.push({
    path: `/locations/${bookingStore.locationId}/guests`,
  });
};

const handleLearnMoreClicked = (serviceId: ServiceId) => {
  router.push(`/locations/${props.locationId}/info/services/${serviceId}`);
};
</script>

<template>
  <h2 class="flex flex-col justify-center my-5">
    <TextHeader1 class="text-center">{{ t('groupPackages.header') }}</TextHeader1>
  </h2>
  <div class="flex justify-center mb-10">
    <ButtonInScreenTertiary class="max-w-[350px] mt-5" @click="handleChooseIndividuallyClicked">
      <div class="flex justify-between items-center px-5">
        <div>
          <TextBody1>{{ t("groupPackages.individualServices") }}</TextBody1>
        </div>
        <Icon :icon="faAngleRight" size="lg"/>
      </div>
    </ButtonInScreenTertiary>
  </div>
  <div class="flex flex-col md:flex-row md:flex-wrap justify-between gap-5 px-5">
    <div v-for="service in services" class="w-full md:w-[calc(50%-0.625rem)] shrink-0 grow-0">
      <ServiceCard @learnMore="() => handleLearnMoreClicked(service.id)" :modelValue="guestsHaveService(service.id)"
        @update:modelValue="(newValue: boolean) => handleServiceToggled(service.id, newValue)"
        :image="ServiceCardImage"
        :header="service.name"
        :bodyText="service.shortDescription"
        :duration="service.typicalDurationMins"
        :priceCents="service.priceCents" />
    </div>
  </div>
</template>

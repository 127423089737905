<script setup lang="ts">
import TextHeader2 from "@/src/components/TextHeader2.vue";

const props = defineProps<{
  labelOne: string;
  valueOne: boolean;
  labelTwo: string;
  valueTwo: boolean;
}>();

const model = defineModel<typeof props.valueOne | typeof props.valueTwo | null>();
</script>

<template>
  <div class="flex gap-5" role="radiogroup">
    <button
      :class="`block w-full p-5 aspect-[5/4] ${model === valueOne ? 'bg-button-binary-selected' : 'bg-button-binary-unselected'}`"
      @click.stop="model = valueOne"
      role="radio"
      :aria-checked="model === valueOne"
    >
      <TextHeader2>
        {{ labelOne }}
      </TextHeader2>
    </button>
    <button
      :class="`block w-full p-5 py-10 ${model === valueTwo ? 'bg-button-binary-selected' : 'bg-button-binary-unselected'}`"
      @click.stop="model = valueTwo"
      role="radio"
      :aria-checked="model === valueTwo"
    >
      <TextHeader2>
        {{ labelTwo }}
      </TextHeader2>
    </button>
  </div>
</template>

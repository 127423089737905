import { apiOriginFromOrigin } from "@/src/config/env";
import {
  type AuthJwt,
  type CreateSessionFromZenotiLoginRequest,
  type CreateUserRequest,
  TownhouseApiClient,
  TownhouseApiError,
  type UserId,
} from "@/src/lib/townhouseApiClient";
import { defineStore } from "pinia";

type AuthState = {
  townhouseApiClient: TownhouseApiClient;
  authJwt: AuthJwt | null;
  userId: UserId | null;
};

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => {
    return {
      townhouseApiClient: new TownhouseApiClient(new URL(apiOriginFromOrigin())),
      authJwt: null,
      userId: null,
    };
  },
  persist: {
    storage: localStorage,
    pick: ["authJwt"],
    afterHydrate: () => {
      const authStore = useAuthStore();
      authStore.townhouseApiClient.setAuthJwt(authStore.authJwt);
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.authJwt,
  },
  actions: {
    // We can't use hydrate since we have to use async, so this has to be called when the app is first mounted
    async verifySession(): Promise<void> {
      if (!this.authJwt) {
        return;
      }
      try {
        const res = await this.townhouseApiClient.sessionGetCurrent();
        this.$patch((state) => {
          state.userId = res.sub;
          state.townhouseApiClient.setAuthJwt(state.authJwt);
        });
      } catch (e) {
        if (e instanceof TownhouseApiError) {
          return this.logout();
        }

        throw e;
      }
    },

    async createAccount(params: CreateUserRequest): Promise<void> {
      const createAccountRes = await this.townhouseApiClient.userCreate(params);
      this.$patch((state) => {
        state.userId = createAccountRes.id;
        state.authJwt = createAccountRes.token;
        state.townhouseApiClient.setAuthJwt(state.authJwt);
      });
    },

    async login(params: CreateSessionFromZenotiLoginRequest): Promise<void> {
      const loginRes = await this.townhouseApiClient.sessionCreateFromZenotiLogin(params);
      this.$patch((state) => {
        state.userId = loginRes.userId;
        state.authJwt = loginRes.token;
        state.townhouseApiClient.setAuthJwt(state.authJwt);
      });
    },

    logout() {
      this.$reset();
    },
  },
});

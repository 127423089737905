<script setup lang="ts">
import ClosePopup from "@/src/components/ClosePopup.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { isCallbackEvent } from "@/src/lib/callbackEvent";
import { onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  addCardUrl: URL;
}>();

const emit = defineEmits<{
  success: [];
  failed: [reason: string];
  cancelled: [];
}>();

const handleIframeMessage = (event: MessageEvent) => {
  if (!isCallbackEvent(event)) {
    return;
  }

  const params = new URLSearchParams(event.data.params);

  if (params.get("success") === "1") {
    emit("success");
    return;
  }

  emit("failed", params.get("message") || t("addPaymentAccount.unknownError"));
};

onMounted(() => {
  // Listen for postMessage from the iframe
  window.addEventListener("message", handleIframeMessage);
});

onUnmounted(() => {
  // Clean up the event listener when the component is destroyed
  window.removeEventListener("message", handleIframeMessage);
});
</script>

<template>
  <div role="dialog" aria-modal="true" @click="$emit('cancelled')" @scroll.prevent @wheel.prevent @touchmove.prevent class="fixed inset-0 z-20 w-screen h-screen bg-popup-background p-10 flex justify-center items-start">
    <div role="form" @click.stop class="w-[600px] bg-white p-10 flex flex-col gap-5 relative">
      <ClosePopup @click="$emit('cancelled')" />
      <TextHeader1 class="mb-10 text-center">{{ t('addPaymentAccount.header') }}</TextHeader1>
      <iframe class="w-full h-[600px]" :src="addCardUrl.toString()"></iframe>
    </div>
  </div>
</template>

<script setup lang="ts">
import AuthCreateAccount from "@/src/components/AuthCreateAccount.vue";
import AuthLogin from "@/src/components/AuthLogin.vue";
import AuthResetPassword from "@/src/components/AuthResetPassword.vue";
import ClosePopup from "@/src/components/ClosePopup.vue";
import type { LocationId } from "@/src/lib/townhouseApiClient";
import { useAuthStore } from "@/src/stores/authStore";
import { ref } from "vue";

defineProps<{
  locationId: LocationId;
}>();

defineEmits<{
  cancelled: [];
}>();

const authStore = useAuthStore();

const state = ref<"login" | "createAccount" | "resetPassword">("login");
</script>

<template>
  <div v-if="!authStore.isLoggedIn" role="dialog" aria-modal="true" @click="$emit('cancelled')" @scroll.prevent @wheel.prevent @touchmove.prevent class="fixed inset-0 z-20 w-screen h-screen bg-popup-background p-10 flex justify-center items-start">
    <div role="form" @click.stop class="w-[600px] bg-white relative">
      <ClosePopup @click="$emit('cancelled')" />
      <AuthLogin v-if="state === 'login'" @clicked-forgotten-password="state = 'resetPassword'" @clicked-create-account="state = 'createAccount'" />
      <AuthCreateAccount v-else-if="state === 'createAccount'" :location-id="locationId" @clicked-login="state = 'login'" />
      <AuthResetPassword v-else-if="state === 'resetPassword'" @reset-password-complete="state = 'login'" state="email"/>
    </div>
  </div>
</template>

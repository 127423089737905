import { serviceToSummary } from "@/src/lib/summaryHelpers";
import {
  type AddOn,
  type AddOnId,
  type AddOnName,
  type BookingReservationId,
  type CreateBookingRequest,
  type Employee,
  type LocationId,
  type LocationName,
  type MedicalOrAccessNotesInfo,
  type PaymentAccount,
  type Removal,
  type RemovalId,
  type RemovalName,
  type ServiceId,
  type ServiceName,
  type ServiceType,
  type ServicesGetByLocationResponseItem,
  type UserCreatePaymentAccountByLocationResponse,
  type ZenotiPaymentAccountId,
  bookingGetAvailableEmployeesResponseSchema,
  locationGetInfoResponseSchema,
  servicesGetByLocationResponseSchema,
  userGetPaymentAccountsByLocationResponseSchema,
} from "@/src/lib/townhouseApiClient";
import { useAuthStore } from "@/src/stores/authStore";
import _ from "lodash";
import { DateTime } from "luxon";
import { type StateTree, defineStore } from "pinia";
import { z } from "zod";

export type GuestNumber = 1 | 2 | 3 | 4 | 5 | 6;

export type SingleServiceSummary = {
  serviceName: ServiceName;
  addOnNames: AddOnName[];
  removalNames: RemovalName[];
};

export type ParallelServiceSummary = {
  serviceOne: SingleServiceSummary;
  serviceTwo: SingleServiceSummary;
};

export type ServiceSummary = SingleServiceSummary | ParallelServiceSummary;

export const isSingleServiceSummary = (summary: ServiceSummary): summary is SingleServiceSummary => {
  return "serviceName" in summary;
};

export const isParallelServiceSummary = (summary: ServiceSummary): summary is ParallelServiceSummary => {
  return "serviceOne" in summary;
};

export type BookingSummary = {
  locationName: LocationName;
  // Outer array per guest, inner array per service (single or parallel)
  serviceSummariesByGuest: ServiceSummary[][];
  bookingDateTimeUtc: DateTime;
};

const bookingStateServiceSchema = z.object({
  addOnIds: z.array(z.string()).transform((arr) => new Set(arr) as Set<AddOnId>),
  needsRemoval: z.boolean().nullable(),
  removalIds: z.array(z.string()).transform((arr) => new Set(arr) as Set<RemovalId>),
  inParallelWith: z
    .string()
    .transform((s) => s as ServiceId)
    .nullable(),
});

const serviceByGuestSchema = z
  .record(z.string(), bookingStateServiceSchema)
  .transform((obj) => new Map(Object.entries(obj)) as Map<ServiceId, (typeof obj)[string]>);

export type ServiceByGuest = z.output<typeof serviceByGuestSchema>;
export type ServicesByLocation = z.output<typeof servicesGetByLocationResponseSchema>;

const bookingStateSchema = z.object({
  locationId: z
    .string()
    .transform((s) => s as LocationId)
    .nullable(),
  locationInfo: locationGetInfoResponseSchema.nullable(),
  servicesByLocation: servicesGetByLocationResponseSchema.nullable(),
  servicesByGuest: z.array(serviceByGuestSchema),
  bookingDateUtc: z
    .string()
    .date()
    .transform((s) => DateTime.fromISO(s, { zone: "UTC" }) as DateTime)
    .nullable(),
  bookingDateTimeUtc: z
    .string()
    .datetime()
    .transform((s) => DateTime.fromISO(s, { zone: "UTC" }) as DateTime)
    .nullable(),
  availableEmployees: bookingGetAvailableEmployeesResponseSchema.shape.employees.nullable(),
  employeeChosen: bookingGetAvailableEmployeesResponseSchema.shape.employees.element.nullable(),
  availableDateTimesUtc: z
    .array(
      z
        .string()
        .datetime()
        .transform((s) => DateTime.fromISO(s, { zone: "UTC" }) as DateTime),
    )
    .nullable(),
  bookingReservationId: z
    .string()
    .transform((s) => s as BookingReservationId)
    .nullable(),
  expiryDateTimeUtc: z
    .string()
    .datetime()
    .transform((s) => DateTime.fromISO(s, { zone: "UTC" }) as DateTime)
    .nullable(),
  paymentAccounts: userGetPaymentAccountsByLocationResponseSchema.nullable(),
  paymentAccountChosen: userGetPaymentAccountsByLocationResponseSchema.element.nullable(),
  termsAndConditionsAccepted: z.boolean().nullable(),
  bookingComplete: z.boolean().default(false),
  medicalOrAccessNotes: z
    .string()
    .transform((s) => s as MedicalOrAccessNotesInfo)
    .nullable(),
});

export type BookingStateService = z.output<typeof bookingStateServiceSchema>;
export type BookingState = z.output<typeof bookingStateSchema>;

export class BookingStoreNotExistentGuestError extends Error {}
export class BookingStoreInvalidServiceError extends Error {}
export class BookingStoreServiceAlreadyAddedError extends Error {}
export class BookingStoreNonExistentParallelServiceError extends Error {}
export class BookingStoreNonExistentServiceError extends Error {}
export class BookingStoreInvalidBookingDateTimeError extends Error {}
export class BookingStoreIncompleteError extends Error {}
export class BookingStoreNoReservationError extends Error {}

export const useBookingStore = defineStore("booking", {
  state: (): BookingState => {
    return {
      locationId: null,
      locationInfo: null,
      servicesByLocation: null,
      servicesByGuest: [],
      bookingDateUtc: null,
      bookingDateTimeUtc: null,
      availableEmployees: null,
      employeeChosen: null,
      availableDateTimesUtc: null,
      bookingReservationId: null,
      expiryDateTimeUtc: null,
      paymentAccounts: null,
      paymentAccountChosen: null,
      termsAndConditionsAccepted: null,
      bookingComplete: false,
      medicalOrAccessNotes: null,
    };
  },
  persist: {
    storage: localStorage,
    serializer: {
      serialize: (): string => {
        const bookingState = useBookingStore();

        return JSON.stringify({
          locationId: bookingState.locationId,
          // ES6 Map can't be serialised to JSON, will result in `{}` so must be done by hand
          servicesByGuest: bookingState.$state.servicesByGuest.map((services) => {
            return _.mapValues(Object.fromEntries(services.entries()), (service) => {
              return {
                ...service,
                // Sets also can't be serialised to JSON, will also result in `{}` so must be done by hand
                addOnIds: Array.from(service.addOnIds),
                removalIds: Array.from(service.removalIds),
              };
            });
          }),
          bookingComplete: bookingState.bookingComplete,
          // It never makes sense to allow the user to leave the page, come back, and have these preserved
          locationInfo: null,
          servicesByLocation: null,
          bookingDateUtc: null,
          bookingDateTimeUtc: null,
          availableEmployees: null,
          employeeChosen: null,
          availableDateTimesUtc: null,
          bookingReservationId: null,
          expiryDateTimeUtc: null,
          paymentAccounts: null,
          paymentAccountChosen: null,
          termsAndConditionsAccepted: null,
          medicalOrAccessNotes: null,
        });
      },
      deserialize: (data: string): StateTree => {
        try {
          return bookingStateSchema.parse(JSON.parse(data));
        } catch (_e) {
          // FIXME: Report to Sentry, but do not allow the booking flow to be broken
          return {};
        }
      },
    },
  },
  getters: {
    numberOfGuests(): number {
      return this.servicesByGuest.length;
    },
    hasMultipleGuests(): boolean | null {
      if (this.servicesByGuest.length === 0) {
        return null;
      }

      return this.servicesByGuest.length > 1;
    },
    hasSelectedNumberOfGuests(): boolean {
      return this.servicesByGuest.length > 0;
    },
    hasPopulatedAllGuests(): boolean {
      return (
        this.servicesByGuest.length > 0 && this.servicesByGuest.every((servicesForGuest) => servicesForGuest.size > 0)
      );
    },
    servicesByLocationGroupedByType(): Record<ServiceType, ServicesGetByLocationResponseItem[]> {
      const servicesArray = Array.from(this.servicesByLocation?.values() || []);
      return _.merge(
        {
          manicure: [],
          pedicure: [],
          maniPediManicure: [],
          maniPediPedicure: [],
          groupPackage: [],
          other: [],
        },
        _.groupBy(servicesArray, (service) => service.serviceType),
      );
    },
  },
  actions: {
    chosenGroupPackage(): ServiceId | null {
      if (this.servicesByGuest.length === 0 || !Array.from(this.servicesByGuest[0].keys())[0]) {
        return null;
      }

      const serviceId = Array.from(this.servicesByGuest[0].keys())[0];
      const service = this.servicesByLocation?.get(serviceId);
      if (!service) {
        throw new BookingStoreNonExistentServiceError(
          `Failed to get service details for service with ID: '${serviceId}'`,
        );
      }

      if (service.serviceType !== "groupPackage") {
        return null;
      }

      return serviceId;
    },
    isGroupPackageChosen(): boolean {
      return this.chosenGroupPackage() !== null;
    },
    setLocation(locationId: LocationId) {
      if (this.locationId === locationId) {
        return;
      }

      this.$reset();
      this.$patch({
        locationId,
      });
    },

    async fetchLocationInfo(): Promise<void> {
      if (!this.locationId) {
        throw new BookingStoreIncompleteError("Unable to fetch location info as no location has been selected");
      }

      const authStore = useAuthStore();

      if (this.locationInfo) {
        return;
      }

      const locationInfo = await authStore.townhouseApiClient.locationGetInfo(this.locationId);
      this.$patch({
        locationInfo,
      });
    },

    async fetchServices(): Promise<void> {
      if (!this.locationId) {
        throw new BookingStoreIncompleteError("Unable to fetch services as no location has been selected");
      }

      const authStore = useAuthStore();

      if (this.servicesByLocation) {
        return;
      }

      const servicesByLocation = await authStore.townhouseApiClient.servicesGetByLocation(this.locationId);
      this.$patch({
        servicesByLocation,
      });
    },

    getAddOnsFromServiceId(serviceId: ServiceId): AddOn[] {
      const service = this.servicesByLocation?.get(serviceId);
      if (!service) {
        throw new BookingStoreNonExistentServiceError(
          `Failed to get service details for service with ID: '${serviceId}'`,
        );
      }
      return Array.from(service.addOns.values());
    },

    getRemovalsFromServiceId(serviceId: ServiceId): Removal[] {
      const service = this.servicesByLocation?.get(serviceId);
      if (!service) {
        throw new BookingStoreNonExistentServiceError(
          `Failed to get service details for service with ID: '${serviceId}'`,
        );
      }
      return Array.from(service.removals.values());
    },

    copyServicesToAllGuests() {
      const firstGuestServices = this.servicesByGuest[0];

      if (!firstGuestServices) {
        throw new BookingStoreNonExistentServiceError("Failed to get service details for first guest");
      }

      const newServicesByGuest = this.servicesByGuest.map((services, index) => {
        if (index === 0) {
          return services;
        }
        return _.cloneDeep(firstGuestServices);
      });

      this.$patch({
        servicesByGuest: newServicesByGuest,
      });
    },

    setNumberOfGuests(numberOfGuests: GuestNumber) {
      if (numberOfGuests === this.servicesByGuest.length) {
        return;
      }

      if (this.isGroupPackageChosen() && numberOfGuests <= 2) {
        this.$patch({
          servicesByGuest: [],
        });
      }

      if (numberOfGuests < this.servicesByGuest.length) {
        // Truncate the array if needed
        this.$patch({
          servicesByGuest: this.servicesByGuest.slice(0, numberOfGuests),
        });

        this.clearBookingDatesAndTimes();
        return;
      }

      // Expand the array if a higher number of guests are chosen than previous
      if (this.isGroupPackageChosen()) {
        this.$patch((state) => {
          // If a group package is currently chosen and more guests are added than initially specified
          // we want all the new guests to have the group package service chosen but no add ons chosen
          const [id, service] = Array.from(state.servicesByGuest[0])[0];
          _.times(numberOfGuests - this.servicesByGuest.length, () =>
            state.servicesByGuest.push(new Map(new Map([[id, { ...service, addOnIds: new Set([]) }]]))),
          );
        });

        return;
      }

      this.$patch((state) => {
        _.times(numberOfGuests - this.servicesByGuest.length, () => state.servicesByGuest.push(new Map()));
      });

      this.clearBookingDatesAndTimes();
    },

    addServiceToGuest(guestNumber: GuestNumber, serviceId: ServiceId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to add a service to guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        if (state.servicesByGuest[guestNumber - 1].has(serviceId)) {
          throw new BookingStoreServiceAlreadyAddedError(
            `The service with ID '${serviceId}' is already added to guest ${guestNumber}`,
          );
        }

        if (!this.servicesByLocation?.has(serviceId)) {
          throw new BookingStoreInvalidServiceError(
            `The service with ID '${serviceId}' is not available at location '${this.locationId}'`,
          );
        }

        state.servicesByGuest[guestNumber - 1].set(serviceId, {
          addOnIds: new Set(),
          needsRemoval: null,
          removalIds: new Set(),
          inParallelWith: null,
        });
      });

      this.clearBookingDatesAndTimes();
    },

    addParallelServicesToGuest(guestNumber: GuestNumber, serviceIdOne: ServiceId, serviceIdTwo: ServiceId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to add parallel services to guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        if (state.servicesByGuest[guestNumber - 1].has(serviceIdOne)) {
          throw new BookingStoreServiceAlreadyAddedError(
            `The service with ID '${serviceIdOne}' is already added to guest ${guestNumber}`,
          );
        }

        if (state.servicesByGuest[guestNumber - 1].has(serviceIdTwo)) {
          throw new BookingStoreServiceAlreadyAddedError(
            `The service with ID '${serviceIdTwo}' is already added to guest ${guestNumber}`,
          );
        }

        if (!this.servicesByLocation?.has(serviceIdOne)) {
          throw new BookingStoreInvalidServiceError(
            `The service with ID '${serviceIdOne}' is not available at location '${this.locationId}'`,
          );
        }

        if (!this.servicesByLocation?.has(serviceIdTwo)) {
          throw new BookingStoreInvalidServiceError(
            `The service with ID '${serviceIdTwo}' is not available at location '${this.locationId}'`,
          );
        }

        state.servicesByGuest[guestNumber - 1].set(serviceIdOne, {
          addOnIds: new Set(),
          needsRemoval: null,
          removalIds: new Set(),
          inParallelWith: serviceIdTwo,
        });

        state.servicesByGuest[guestNumber - 1].set(serviceIdTwo, {
          addOnIds: new Set(),
          needsRemoval: null,
          removalIds: new Set(),
          inParallelWith: serviceIdOne,
        });
      });

      this.clearBookingDatesAndTimes();
    },

    addAddOnToGuest(guestNumber: GuestNumber, serviceId: ServiceId, addOnId: AddOnId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to add an add-on to guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        const service = state.servicesByGuest[guestNumber - 1].get(serviceId);

        if (!service) {
          throw new BookingStoreNonExistentServiceError(
            `Attempted to add an add-on with ID '${addOnId}' to a service with ID '${serviceId}' that did not exist`,
          );
        }

        if (!this.servicesByLocation?.get(serviceId)?.addOns.get(addOnId)) {
          throw new BookingStoreInvalidServiceError(
            `The add-on with ID '${addOnId}' is not available at location '${this.locationId}'`,
          );
        }

        service.addOnIds.add(addOnId);
      });

      this.clearBookingDatesAndTimes();
    },

    setNeedsRemovalForGuest(guestNumber: GuestNumber, serviceId: ServiceId, needsRemoval: boolean) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to set needing removal on guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        const service = state.servicesByGuest[guestNumber - 1].get(serviceId);

        if (!service) {
          throw new BookingStoreNonExistentServiceError(
            `Attempted to set needing removal on a service with ID '${serviceId}' that did not exist`,
          );
        }

        service.needsRemoval = needsRemoval;
        if (!needsRemoval) {
          service.removalIds.clear();
        }
      });

      this.clearBookingDatesAndTimes();
    },

    getNeedsRemovalForGuest(guestNumber: GuestNumber, serviceId: ServiceId): boolean | null {
      const guestServices = this.servicesByGuest[guestNumber - 1];

      if (!guestServices) {
        throw new BookingStoreNotExistentGuestError(
          `Attempted to get needs removal on guest ${guestNumber} but there are only ${this.servicesByGuest.length} guests`,
        );
      }

      const service = guestServices.get(serviceId);

      if (!service) {
        throw new BookingStoreNonExistentServiceError(
          `Attempted to get needs removal on a service with ID '${serviceId}' that did not exist`,
        );
      }

      return service.needsRemoval;
    },

    addRemovalToGuest(guestNumber: GuestNumber, serviceId: ServiceId, removalId: RemovalId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to add a removal to guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        const service = state.servicesByGuest[guestNumber - 1].get(serviceId);

        if (!service) {
          throw new BookingStoreNonExistentServiceError(
            `Attempted to add a removal with ID '${removalId}' to a service with ID '${serviceId}' that did not exist`,
          );
        }

        if (!this.servicesByLocation?.get(serviceId)?.removals.get(removalId)) {
          throw new BookingStoreInvalidServiceError(
            `The removal with ID '${removalId}' is not available at location '${this.locationId}'`,
          );
        }

        service.needsRemoval = true;
        service.removalIds.add(removalId);
      });

      this.clearBookingDatesAndTimes();
    },

    removeAllServicesFromGuest(guestNumber: GuestNumber) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to remove all services from guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        state.servicesByGuest[guestNumber - 1].clear();
      });

      this.clearBookingDatesAndTimes();
    },

    removeServiceFromGuest(guestNumber: GuestNumber, serviceId: ServiceId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to remove a service from guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        state.servicesByGuest[guestNumber - 1].delete(serviceId);
      });

      this.clearBookingDatesAndTimes();
    },

    removeParallelServicesFromGuest(guestNumber: GuestNumber, serviceIdOne: ServiceId, serviceIdTwo: ServiceId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to remove parallel services from guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        state.servicesByGuest[guestNumber - 1].delete(serviceIdOne);
        state.servicesByGuest[guestNumber - 1].delete(serviceIdTwo);
      });

      this.clearBookingDatesAndTimes();
    },

    removeAddOnFromGuest(guestNumber: GuestNumber, serviceId: ServiceId, addOnId: AddOnId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to remove an add-on from guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        const service = state.servicesByGuest[guestNumber - 1].get(serviceId);

        if (!service) {
          throw new BookingStoreNonExistentServiceError(
            `Attempted to remove an add-on with ID '${addOnId}' from a service with ID '${serviceId}' that did not exist`,
          );
        }

        service.addOnIds.delete(addOnId);
      });

      this.clearBookingDatesAndTimes();
    },

    removeRemovalFromGuest(guestNumber: GuestNumber, serviceId: ServiceId, removalId: RemovalId) {
      this.$patch((state) => {
        if (!state.servicesByGuest[guestNumber - 1]) {
          throw new BookingStoreNotExistentGuestError(
            `Attempted to remove a removal from guest ${guestNumber} but there are only ${state.servicesByGuest.length} guests`,
          );
        }

        const service = state.servicesByGuest[guestNumber - 1].get(serviceId);

        if (!service) {
          throw new BookingStoreNonExistentServiceError(
            `Attempted to remove a removal with ID '${removalId}' from a service with ID '${serviceId}' that did not exist`,
          );
        }

        service.removalIds.delete(removalId);
      });

      this.clearBookingDatesAndTimes();
    },

    clearBookingDatesAndTimes() {
      this.$patch({
        availableEmployees: null,
        employeeChosen: null,
        bookingDateUtc: null,
        bookingDateTimeUtc: null,
        availableDateTimesUtc: null,
        bookingReservationId: null,
        expiryDateTimeUtc: null,
      });
    },

    async fetchAvailableEmployees(): Promise<void> {
      const authStore = useAuthStore();

      if (this.availableEmployees && this.availableEmployees.length > 0) {
        return;
      }

      if (!(authStore.isLoggedIn && authStore.userId)) {
        throw new BookingStoreIncompleteError("Unable to fetch available employees as there is no user set");
      }

      const createBookingRequest = this.constructCreateBookingRequest(DateTime.now().toUTC());
      const availableEmployees = await authStore.townhouseApiClient.bookingGetAvailableEmployees(createBookingRequest);

      this.$patch({
        availableEmployees: availableEmployees.employees,
      });
    },

    async setChosenEmployee(chosenEmployee: Employee | null): Promise<void> {
      this.$patch({
        employeeChosen: chosenEmployee,
      });

      if (this.availableDateTimesUtc) {
        await this.refetchAvailableTimes();
      }
    },

    setBookingDateUtc(bookingDateUtc: DateTime) {
      this.$patch({
        bookingDateUtc,
        bookingDateTimeUtc: null,
        availableDateTimesUtc: null,
        bookingReservationId: null,
        expiryDateTimeUtc: null,
      });
    },

    constructCreateBookingRequest(bookingDateTimeUtc: DateTime): CreateBookingRequest {
      if (!(this.locationId && this.hasPopulatedAllGuests)) {
        throw new BookingStoreIncompleteError(
          "Unable to start booking process as either the location is not set, or there are no guests with services",
        );
      }

      return {
        locationId: this.locationId,
        bookingDateTimeUtc,
        zenotiEmployeeId: this.employeeChosen?.zenotiEmployeeId || null,
        servicesByGuest: this.servicesByGuest.map((servicesForGuest) => {
          return Array.from(servicesForGuest.entries()).map(([serviceId, rest]) => {
            return {
              serviceId,
              addOnIds: Array.from(rest.addOnIds),
              removalIds: Array.from(rest.removalIds),
              inParallelWith: rest.inParallelWith,
            };
          });
        }),
      };
    },

    async fetchAvailableTimes(): Promise<void> {
      const authStore = useAuthStore();

      if (this.availableDateTimesUtc && this.availableDateTimesUtc.length > 0) {
        return;
      }

      if (!this.bookingDateUtc) {
        throw new BookingStoreIncompleteError("Unable to start booking process as booking date is not selected");
      }

      const createBookingRequest = this.constructCreateBookingRequest(this.bookingDateUtc);
      const availableDateTimesUtc = await authStore.townhouseApiClient.bookingGetAvailableTimes(createBookingRequest);
      this.$patch({
        availableDateTimesUtc,
        bookingDateTimeUtc: null,
        bookingReservationId: null,
        expiryDateTimeUtc: null,
      });
    },

    async refetchAvailableTimes(): Promise<void> {
      this.$patch({
        availableDateTimesUtc: null,
        bookingDateTimeUtc: null,
        bookingReservationId: null,
        expiryDateTimeUtc: null,
      });

      await this.fetchAvailableTimes();
    },

    setBookingDateTimeUtc(bookingDateTimeUtc: DateTime) {
      if (!this.availableDateTimesUtc?.includes(bookingDateTimeUtc)) {
        throw new BookingStoreInvalidBookingDateTimeError(
          "Unable to set booking date / time to any value that wasn't in the available times",
        );
      }

      this.$patch({
        bookingDateTimeUtc,
        bookingReservationId: null,
        expiryDateTimeUtc: null,
      });
    },

    createBookingSummary(): BookingSummary {
      if (!(this.locationInfo && this.servicesByLocation && this.hasPopulatedAllGuests && this.bookingDateTimeUtc)) {
        throw new BookingStoreIncompleteError("Unable to get booking summary as some details have not been selected");
      }

      // Typescript can't see this isn't null inside the serviceToSummary function otherwise
      const servicesByLocation = this.servicesByLocation;
      const serviceSummariesByGuest = this.servicesByGuest.map((servicesForGuest) =>
        Array.from(
          Array.from(servicesForGuest.entries())
            .reduce((acc, [serviceId, service]) => {
              if (service.inParallelWith) {
                const [firstServiceId, secondServiceId] = [serviceId, service.inParallelWith].sort();
                const firstService = servicesForGuest.get(firstServiceId);
                const secondService = servicesForGuest.get(secondServiceId);

                if (!(firstService && secondService)) {
                  throw new BookingStoreNonExistentParallelServiceError(
                    `Attempted to associate parallel services with IDs ${firstServiceId} and ${secondServiceId} where at least one does not exist`,
                  );
                }

                return acc.set(`${firstServiceId}-${secondServiceId}`, {
                  serviceOne: serviceToSummary(servicesByLocation, firstServiceId, firstService),
                  serviceTwo: serviceToSummary(servicesByLocation, secondServiceId, secondService),
                } as ParallelServiceSummary);
              }
              return acc.set(serviceId, serviceToSummary(servicesByLocation, serviceId, service));
            }, new Map<string, ServiceSummary>())
            .values(),
        ),
      );

      return {
        locationName: this.locationInfo.name,
        serviceSummariesByGuest,
        bookingDateTimeUtc: this.bookingDateTimeUtc,
      };
    },

    calculateTotalBookingPriceCents(): number {
      if (!(this.servicesByLocation && this.hasPopulatedAllGuests && this.bookingDateTimeUtc)) {
        throw new BookingStoreIncompleteError(
          "Unable to get booking total price as some details have not been selected",
        );
      }

      let bookingTotalPriceCents = 0;
      for (const servicesForGuest of this.servicesByGuest) {
        for (const [serviceId, service] of servicesForGuest) {
          const serviceInfo = this.servicesByLocation?.get(serviceId);
          if (!serviceInfo) {
            throw new BookingStoreNonExistentServiceError(`Attempted to find a price for service with ID ${serviceId}`);
          }

          const servicePriceCents = serviceInfo.priceCents;

          const totalAddOnsPriceCents = _.sum(
            Array.from(service.addOnIds).map((addOnId) => {
              const addOnPriceCents = serviceInfo.addOns.get(addOnId)?.priceCents;

              if (!addOnPriceCents) {
                throw new BookingStoreNonExistentServiceError(`Attempted to find a price for addOn with ID ${addOnId}`);
              }

              return addOnPriceCents;
            }),
          );

          const totalRemovalsPriceCents = _.sum(
            Array.from(service.removalIds).map((removalId) => {
              const addOnPriceCents = serviceInfo.removals.get(removalId)?.priceCents;

              if (!addOnPriceCents) {
                throw new BookingStoreNonExistentServiceError(
                  `Attempted to find a price for removal with ID ${removalId}`,
                );
              }

              return addOnPriceCents;
            }),
          );

          bookingTotalPriceCents += servicePriceCents + totalAddOnsPriceCents + totalRemovalsPriceCents;
        }
      }

      return bookingTotalPriceCents;
    },

    async fetchPaymentAccounts(): Promise<void> {
      const authStore = useAuthStore();

      if (this.paymentAccounts && this.paymentAccounts.length > 0) {
        return;
      }

      if (!(authStore.isLoggedIn && authStore.userId)) {
        throw new BookingStoreIncompleteError("Unable to fetch payment accounts as there is no user set");
      }

      const paymentAccounts = await authStore.townhouseApiClient.userGetPaymentAccounts(authStore.userId);
      this.$patch({
        paymentAccounts,
        paymentAccountChosen: paymentAccounts.length > 0 ? paymentAccounts[0] : null,
      });
    },

    setChosenPaymentAccount(chosenPaymentAccount: PaymentAccount) {
      const authStore = useAuthStore();

      if (!(authStore.isLoggedIn && authStore.userId)) {
        throw new BookingStoreIncompleteError("Unable to set chosen payment account as there is no user set");
      }

      this.$patch({
        paymentAccountChosen: chosenPaymentAccount,
      });
    },

    async refetchPaymentAccounts(): Promise<void> {
      this.$patch({
        paymentAccounts: null,
        paymentAccountChosen: null,
      });

      await this.fetchPaymentAccounts();
    },

    async startCreatePaymentAccount(): Promise<UserCreatePaymentAccountByLocationResponse> {
      const authStore = useAuthStore();

      if (!(authStore.isLoggedIn && authStore.userId)) {
        throw new BookingStoreIncompleteError("Unable to create payment account as there is no user set");
      }

      return await authStore.townhouseApiClient.userCreatePaymentAccount(authStore.userId);
    },

    async deletePaymentAccount(paymentAccountId: ZenotiPaymentAccountId): Promise<void> {
      const authStore = useAuthStore();

      if (!(authStore.isLoggedIn && authStore.userId)) {
        throw new BookingStoreIncompleteError("Unable to delete payment account as there is no user set");
      }

      return await authStore.townhouseApiClient.userDeletePaymentAccount(authStore.userId, paymentAccountId);
    },

    clearBookingDateTimeUtc() {
      this.$patch({
        bookingDateUtc: null,
        availableDateTimesUtc: null,
        bookingDateTimeUtc: null,
        bookingReservationId: null,
        expiryDateTimeUtc: null,
      });
    },

    async reserveBooking(): Promise<void> {
      if (!this.bookingDateTimeUtc) {
        throw new BookingStoreIncompleteError("Unable to start booking process as booking date / time is not selected");
      }

      const createBookingRequest = this.constructCreateBookingRequest(this.bookingDateTimeUtc);
      const authStore = useAuthStore();
      const createReservationResponse =
        await authStore.townhouseApiClient.bookingCreateReservation(createBookingRequest);

      this.$patch({
        ...createReservationResponse,
      });
    },

    async confirmBooking(): Promise<void> {
      if (!this.bookingReservationId) {
        throw new BookingStoreNoReservationError("No booking reservation has been created so nothing can be confirmed");
      }

      const authStore = useAuthStore();
      await authStore.townhouseApiClient.bookingCreateConfirmation(this.bookingReservationId, {
        medicalOrAccessNotes: this.medicalOrAccessNotes,
      });
      this.$patch({
        bookingComplete: true,
      });
    },

    setMedicalOrAccessNotesInfo(medicalOrAccessNotes: string | null) {
      this.$patch({
        medicalOrAccessNotes: medicalOrAccessNotes ? medicalOrAccessNotes.trim() : null,
      });
    },
  },
});

import "@/src/assets/main.css";

import App from "@/src/App.vue";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";

import { apiOriginFromOrigin, envNameFromOrigin } from "@/src/config/env";
import { localEnGb } from "@/src/config/locales/en-GB";
import { localEnUs } from "@/src/config/locales/en-US";
import { router } from "@/src/router";
// biome-ignore lint/style/noNamespaceImport: Sentry has to be imported like this
import * as Sentry from "@sentry/vue";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";

// We treat the GB i18n locale as the source of truth – all other locales must
// have every key this one has.
type I18nSchema = typeof localEnGb;

const env = envNameFromOrigin();

let locale: "en-GB" | "en-US";
if (["us-production", "us-staging"].includes(env)) {
  locale = "en-US";
} else {
  locale = "en-GB";
}

const i18n = createI18n<[I18nSchema], "en-GB" | "en-US">({
  legacy: false,
  locale,
  fallbackLocale: "en-GB",
  numberFormats: {
    "en-GB": {
      currency: {
        style: "currency",
        currency: "GBP",
        notation: "standard",
        maximumFractionDigits: 0,
      },
    },
    "en-US": {
      currency: {
        style: "currency",
        currency: "USD",
        notation: "standard",
        maximumFractionDigits: 0,
      },
    },
  },
  messages: {
    "en-GB": localEnGb,
    "en-US": localEnUs,
  },
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
pinia.use(Sentry.createSentryPiniaPlugin());

const app = createApp(App);

if (env !== "development") {
  Sentry.init({
    app,
    dsn: "https://b3927b03af77c78f756a3929637b636b@o4505952816726016.ingest.us.sentry.io/4508143714893824",
    normalizeDepth: 10,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    environment: env,
    // Tracing
    tracesSampleRate: 1.0,
    tracePropagationTargets: [apiOriginFromOrigin()],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(i18n).use(pinia).use(router).mount("body");

<script setup lang="ts">
import BookingProgress from "@/src/components/BookingProgress.vue";
import Header from "@/src/components/Header.vue";
import MainMenu from "@/src/components/MainMenu.vue";
import NavigationBar from "@/src/components/NavigationBar.vue";
import type { BookingProgressStage, BookingStage } from "@/src/config/stages";
import type { ServiceId } from "@/src/lib/townhouseApiClient";
import { useAuthStore } from "@/src/stores/authStore";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const bookingStore = useBookingStore();

const { locationId } = storeToRefs(bookingStore);

const mainMenuVisible = ref<boolean>(false);
const currentGuestNumber = ref<GuestNumber>();
const currentServiceId = ref<ServiceId>();

const isCallback = ref<boolean>(Boolean(router.currentRoute.value.meta["callback"]));
const currentStage = ref<BookingStage>("location");
const previousStage = ref<BookingStage>("location");
const currentProgressStage = ref<BookingProgressStage>("location");
const sectionTransitionName = computed(() => {
  if (currentStage.value === "dateTime" && !authStore.isLoggedIn) {
    return "section-popup-transition";
  }

  if (currentStage.value === "info") {
    return "section-info-open-transition";
  }

  if (previousStage.value === "info") {
    return "section-info-close-transition";
  }

  return "section-transition";
});

const mainMenuItems = [
  {
    label: t("popup.mainMenu.faq"),
    href: t("general.faq"),
  },
  {
    label: t("popup.mainMenu.help"),
    href: t("general.help"),
  },
  {
    label: t("popup.mainMenu.termsAndConditions"),
    href: t("general.termsAndConditions"),
  },
  {
    label: t("popup.mainMenu.privacyPolicy"),
    href: t("general.privacyPolicy"),
  },
];

watch(router.currentRoute, (newRoute) => {
  isCallback.value = Boolean(newRoute.meta["callback"]);
  previousStage.value = currentStage.value;
  currentStage.value = newRoute.meta["stage"] as BookingStage;
  currentProgressStage.value = newRoute.meta["progressStage"] as BookingProgressStage;

  if ("guestNumber" in newRoute.params) {
    currentGuestNumber.value = newRoute.params["guestNumber"] as unknown as GuestNumber;
  }

  if ("serviceId" in newRoute.params) {
    currentServiceId.value = newRoute.params["serviceId"] as ServiceId;
  }
});

const handleAccountClick = () => {
  router.push({ path: "/account", query: { returnTo: route.fullPath } });
};

const handleMainMenuToggled = () => {
  mainMenuVisible.value = !mainMenuVisible.value;
};

const handleBackClick = () => {
  if (currentStage.value === "guests" || currentStage.value === "groupPackages") {
    return router.push({
      path: `/locations/${bookingStore.locationId}`,
    });
  }
  if (currentStage.value === "numberOfGuests") {
    return router.push({
      path: "/",
    });
  }
  router.back();
};

const navigationNextText = computed(() => {
  switch (currentStage.value) {
    case "dateTime":
      return t("navigationBar.reserve");
    case "confirm":
      return t("navigationBar.confirm");
    default:
      return t("navigationBar.next");
  }
});

const nextDisabled = computed(() => {
  switch (currentStage.value) {
    case "location":
      return !locationId.value;
    case "numberOfGuests":
      return bookingStore.numberOfGuests === 0;
    case "guests":
      return !bookingStore.hasPopulatedAllGuests;
    case "groupPackages":
      return !bookingStore.hasPopulatedAllGuests;
    case "services": {
      if (!currentGuestNumber.value) {
        return true;
      }

      return bookingStore.servicesByGuest[currentGuestNumber.value - 1].size === 0;
    }
    case "addOns":
      return false;
    case "needsRemoval": {
      if (!(currentGuestNumber.value && currentServiceId.value)) {
        return true;
      }

      const service = bookingStore.servicesByGuest[currentGuestNumber.value - 1]?.get(currentServiceId.value);

      if (!service) {
        return true;
      }

      return service.needsRemoval === null;
    }
    case "removals": {
      if (!(currentGuestNumber.value && currentServiceId.value)) {
        return true;
      }

      const service = bookingStore.servicesByGuest[currentGuestNumber.value - 1]?.get(currentServiceId.value);

      if (!service) {
        return true;
      }

      return service.removalIds.size === 0;
    }
    case "dateTime":
      return bookingStore.bookingDateTimeUtc === null;
    case "confirm":
      return !(bookingStore.paymentAccountChosen && bookingStore.termsAndConditionsAccepted);
    default:
      return true;
  }
});

const handleNextClick = async () => {
  switch (currentStage.value) {
    case "location":
      return router.push({
        path: `/locations/${bookingStore.locationId}`,
      });
    case "numberOfGuests": {
      if (bookingStore.numberOfGuests <= 2) {
        return router.push({
          path: `/locations/${bookingStore.locationId}/guests`,
        });
      }
      return router.push({
        path: `/locations/${bookingStore.locationId}/group-packages`,
      });
    }
    case "groupPackages": {
      return router.push({
        path: `/locations/${bookingStore.locationId}/guests`,
      });
    }
    case "guests":
      return router.push({
        path: `/locations/${bookingStore.locationId}/date-time`,
      });
    case "services": {
      if (!currentGuestNumber.value) {
        return;
      }

      const firstServiceId = bookingStore.servicesByGuest[currentGuestNumber.value - 1].keys().next().value;
      return router.push({
        path: `/locations/${bookingStore.locationId}/guests/${currentGuestNumber.value}/services/${firstServiceId}/add-ons`,
      });
    }
    case "addOns": {
      if (bookingStore.isGroupPackageChosen()) {
        return router.push({
          path: `/locations/${bookingStore.locationId}/guests`,
        });
      }
      return router.push({
        path: `/locations/${bookingStore.locationId}/guests/${currentGuestNumber.value}/services/${currentServiceId.value}/needs-removal`,
      });
    }
    case "needsRemoval": {
      if (!(currentGuestNumber.value && currentServiceId.value)) {
        return;
      }

      const service = bookingStore.servicesByGuest[currentGuestNumber.value - 1]?.get(currentServiceId.value);

      if (!service) {
        return;
      }

      if (service.needsRemoval) {
        return router.push({
          path: `/locations/${bookingStore.locationId}/guests/${currentGuestNumber.value}/services/${currentServiceId.value}/removals`,
        });
      }

      return navigateToNextServiceOrDateTime();
    }
    case "removals": {
      return navigateToNextServiceOrDateTime();
    }
    case "dateTime": {
      await bookingStore.reserveBooking();

      return router.push({
        path: `/locations/${bookingStore.locationId}/confirm`,
      });
    }
    case "confirm": {
      await bookingStore.confirmBooking();

      return router.push({
        path: `/locations/${bookingStore.locationId}/complete`,
      });
    }
    default:
      return;
  }
};

const handleStageEditClicked = (stage: BookingProgressStage) => {
  switch (stage) {
    case "location":
      return router.push({
        path: "/",
      });
    case "guests":
      return router.push({
        path: `/locations/${bookingStore.locationId}`,
      });
    case "services": {
      return router.push({
        path: `/locations/${bookingStore.locationId}/guests`,
      });
    }
    case "dateTime":
      return router.push({
        path: `/locations/${bookingStore.locationId}/date-time`,
      });
    default:
      return;
  }
};

const navigateToNextServiceOrDateTime = () => {
  if (!(currentGuestNumber.value && currentServiceId.value)) {
    return;
  }

  const servicesForCurrentGuest = bookingStore.servicesByGuest[currentGuestNumber.value - 1];

  if (!servicesForCurrentGuest) {
    return;
  }

  const serviceIdsForCurrentGuest = Array.from(servicesForCurrentGuest.keys());
  const currentServiceIdx = serviceIdsForCurrentGuest.indexOf(currentServiceId.value);

  // If there are more services after this to work through
  const nextServiceId = serviceIdsForCurrentGuest[currentServiceIdx + 1];
  if (nextServiceId) {
    return router.push({
      path: `/locations/${bookingStore.locationId}/guests/${currentGuestNumber.value}/services/${nextServiceId}/add-ons`,
    });
  }

  if (bookingStore.hasMultipleGuests) {
    return router.push({
      path: `/locations/${bookingStore.locationId}/guests`,
    });
  }

  return router.push({
    path: `/locations/${bookingStore.locationId}/date-time`,
  });
};
</script>

<template>
  <div class="flex flex-col items-center">
    <Transition name="menu-transition">
      <MainMenu v-if="mainMenuVisible"
        :labelsAndHrefValues="mainMenuItems"
        @cancelled="handleMainMenuToggled" />
    </Transition>
    <header v-if="!isCallback && currentStage !== 'account'" class="w-full max-w-[1140px] flex">
      <Header class="w-full" :accountSelectable="authStore.isLoggedIn" @account="handleAccountClick" @main-menu="handleMainMenuToggled" />
    </header>
    <nav v-if="!isCallback && currentStage !== 'account'" class="w-full flex justify-center bg-booking-progress-background">
      <div class="w-full max-w-[1140px] flex p-5">
        <BookingProgress @stage-edit-clicked="handleStageEditClicked" :currentStage="currentProgressStage" />
      </div>
    </nav>
    <div class="flex flex-col gap-10 items-center w-full max-w-[1140px]">
      <main class="w-full max-w-[1140px] flex flex-col justify-center gap-5">
        <RouterView v-slot="{ Component, route }">
          <Transition :name="sectionTransitionName" mode="out-in">
            <div class="mb-36" :key="route.fullPath">
              <component :is="Component" />
            </div>
          </Transition>
        </RouterView>
        <Transition name="navigation-bar-transition">
          <div v-if="!isCallback && !(['location', 'complete', 'info', 'account'].includes(currentStage))" class="w-full fixed bottom-0 left-0 bg-navigation-background">
            <div class="w-full flex justify-center">
              <NavigationBar
                :back-visible="currentStage !== 'confirm'"
                class="w-full max-w-[1140px]"
                :backText="t('navigationBar.back')"
                :nextText="navigationNextText"
                :backDisabled="false"
                :nextDisabled="nextDisabled"
                @backClick="handleBackClick"
                @nextClick="handleNextClick" />
            </div>
          </div>
        </Transition>
      </main>
    </div>
  </div>
</template>

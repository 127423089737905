<script setup lang="ts">
import ButtonPlaintext from "@/src/components/ButtonPlaintext.vue";
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import InputEmail from "@/src/components/InputEmail.vue";
import InputPassword from "@/src/components/InputPassword.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBodyError from "@/src/components/TextBodyError.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import type { Email, Password } from "@/src/lib/townhouseApiClient";
import { useAuthStore } from "@/src/stores/authStore";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const authStore = useAuthStore();

const emits = defineEmits<{
  clickedCreateAccount: [];
  clickedForgottenPassword: [];
}>();

const email = ref<string>();
const password = ref<string>();

const formError = ref<string | undefined>();
const emailError = ref<string | undefined>();
const passwordError = ref<string | undefined>();

const login = async () => {
  formError.value = undefined;
  emailError.value = undefined;
  passwordError.value = undefined;

  const splitEmail = email.value?.split("@");
  if (splitEmail?.length === 2 && splitEmail.every((s) => s.length > 0)) {
    emailError.value = undefined;
  } else {
    emailError.value = t("login.emailInvalid");
  }

  if (password.value && password.value.length >= 8) {
    passwordError.value = undefined;
  } else {
    passwordError.value = t("login.passwordInvalid");
  }

  if (emailError.value || passwordError.value) {
    return;
  }

  try {
    await authStore.login({
      email: email.value as Email,
      password: password.value as Password,
    });
  } catch (e) {
    formError.value = t("login.errored");
  }
};
</script>

<template>
  <div class="p-10 flex flex-col gap-5">
    <TextHeader1 class="mb-10 text-center">{{ t('login.header') }}</TextHeader1>
    <TextBodyError v-if="formError" role="alert">
      <TextBody1>{{ formError }}</TextBody1>
    </TextBodyError>
    <InputEmail v-model="email" label="Email" required :error="emailError"></InputEmail>
    <InputPassword v-model="password" label="Password" required :error="passwordError" autocomplete="current-password"></InputPassword>
    <ButtonPrimary @click="login" class="mt-10">
      {{ t('login.submit') }}
    </ButtonPrimary>
    <ButtonPlaintext @click="emits('clickedForgottenPassword')">
      <TextBody1>{{ t('login.forgottenPassword') }}</TextBody1>
    </ButtonPlaintext>
    <ButtonPlaintext @click="emits('clickedCreateAccount')">
      <TextBody1>{{ t('login.createAccount') }}</TextBody1>
    </ButtonPlaintext>
  </div>
</template>

<script setup lang="ts">
import ServiceCardImage from "@/src/assets/service-card-placeholder.png";
import ServiceInfo from "@/src/components/ServiceInfo.vue";
import type { LocationId, ServiceId } from "@/src/lib/townhouseApiClient";
import { useBookingStore } from "@/src/stores/bookingStore";
import _ from "lodash";
import { computed } from "vue";
import { useRouter } from "vue-router";

const bookingStore = useBookingStore();
const router = useRouter();

const props = defineProps<{
  locationId: LocationId;
  serviceId: ServiceId;
}>();

const serviceInfo = computed(() => {
  return bookingStore.servicesByLocation?.get(props.serviceId);
});

const handleBack = () => {
  router.back();
};
</script>

<template>
  <ServiceInfo v-if="serviceInfo" @back="handleBack"
    :image="ServiceCardImage"
    :serviceName="serviceInfo.name"
    :textBody="serviceInfo.longDescription"
    :durationMins="serviceInfo.typicalDurationMins"
    :priceCents="serviceInfo.priceCents" />
</template>

<script setup lang="ts">
import ButtonInScreenPrimary from "@/src/components/ButtonInScreenPrimary.vue";
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import Icon from "@/src/components/Icon.vue";
import NavigationTop from "@/src/components/NavigationTop.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextLink from "@/src/components/TextLink.vue";
import type { PaymentAccount } from "@/src/lib/townhouseApiClient";
import PopupAddPaymentAccount from "@/src/popups/PopupAddPaymentAccount.vue";
import PopupConfirm from "@/src/popups/PopupConfirm.vue";
import { useBookingStore } from "@/src/stores/bookingStore";
import { faAngleRight, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const bookingStore = useBookingStore();

const addPaymentAccountLink = ref<URL | null>(null);
const addPaymentAccountError = ref<string | null>(null);
const deletePaymentAccountRequested = ref<PaymentAccount | null>(null);
const deletePaymentAccountError = ref<string | null>(null);

const handleBack = () => {
  if (route.query && typeof route.query["returnTo"] === "string") {
    return router.push({ path: "/account", query: { returnTo: route.query["returnTo"] } });
  }
  router.push({ path: "/account" });
};

const handleAddPaymentAccountStart = async () => {
  const res = await bookingStore.startCreatePaymentAccount();
  addPaymentAccountLink.value = res.url;
};

const handleAddPaymentAccountCancelled = () => {
  addPaymentAccountLink.value = null;
};

const handleAddPaymentAccountSuccess = async () => {
  addPaymentAccountLink.value = null;
  await bookingStore.refetchPaymentAccounts();
};

const handleAddPaymentAccountFailed = (reason: string) => {
  addPaymentAccountError.value = reason;
  // FIXME: Do something with it
};

const handleDeletePaymentAccount = (paymentAccount: PaymentAccount) => {
  deletePaymentAccountRequested.value = paymentAccount;
};

const handleDeletePaymentAccountCancelled = () => {
  deletePaymentAccountRequested.value = null;
  deletePaymentAccountError.value = null;
};

const handleDeletePaymentAccountConfirmed = async () => {
  if (!deletePaymentAccountRequested.value) {
    return;
  }

  deletePaymentAccountError.value = null;

  try {
    await bookingStore.deletePaymentAccount(deletePaymentAccountRequested.value.id);
  } catch (e) {
    deletePaymentAccountError.value = t("account.paymentAccounts.deleteFailed");
    return;
  }

  deletePaymentAccountRequested.value = null;
  await bookingStore.refetchPaymentAccounts();
};
</script>

<template>
  <PopupAddPaymentAccount v-if="addPaymentAccountLink"
    @cancelled="handleAddPaymentAccountCancelled"
    @success="handleAddPaymentAccountSuccess"
    @failed="handleAddPaymentAccountFailed"
    :add-card-url="addPaymentAccountLink" />
  <PopupConfirm v-if="deletePaymentAccountRequested"
    :prompt="t('account.paymentAccounts.confirmDelete', { cardLast4: deletePaymentAccountRequested.lastFour })"
    :error="deletePaymentAccountError || undefined"
    @cancelled="handleDeletePaymentAccountCancelled"
    @confirmed="handleDeletePaymentAccountConfirmed"
    />
  <div class="w-full max-w-[1140px] flex flex-col justify-center items-center relative">
    <TextHeader1 class="p-10">{{ t("account.paymentAccounts.label") }}</TextHeader1>
    <NavigationTop @back="handleBack" />
    <div v-if="bookingStore.paymentAccounts" class="w-full p-10 flex flex-col items-center gap-5">
      <ButtonInScreenPrimary v-for="paymentAccount in bookingStore.paymentAccounts" class="w-full max-w-[600px]"  @click="handleDeletePaymentAccount(paymentAccount)">
        <div class="flex justify-between items-center gap-5">
          <div>
            <!-- FIXME: Show correct icon based on payment method -->
            <Icon class="mr-5" :icon="faCreditCard" size="lg"/>
            <TextBody1>{{ t("account.paymentAccounts.card", { cardLast4: paymentAccount.lastFour}) }}</TextBody1>
          </div>
          <TextLink>
            <TextBody1>{{ t("account.paymentAccounts.delete") }}</TextBody1>
          </TextLink>
        </div>
      </ButtonInScreenPrimary>
    </div>
    <div class="w-full p-10 flex flex-col items-center">
      <ButtonInScreenTertiary @click="handleAddPaymentAccountStart" class="w-full max-w-[600px]">
        <div class="flex justify-between items-center">
          <div>
            <Icon class="mr-5" :icon="faCreditCard" size="lg"/>
            <TextBody1>{{ t("confirm.payment.addCard") }}</TextBody1>
          </div>
          <Icon :icon="faAngleRight" size="lg"/>
        </div>
      </ButtonInScreenTertiary>
    </div>
  </div>
</template>

<script setup lang="ts">
import ServiceCardImage from "@/src/assets/service-card-placeholder.png";
import ServiceCard from "@/src/components/ServiceCard.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import type { AddOnId, LocationId, ServiceId } from "@/src/lib/townhouseApiClient";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const bookingStore = useBookingStore();
const router = useRouter();

const props = defineProps<{
  locationId: LocationId;
  guestNumber: GuestNumber;
  serviceId: ServiceId;
}>();

const serviceType = computed(() => bookingStore.servicesByLocation?.get(props.serviceId)?.serviceType);
const addOns = computed(() => bookingStore.getAddOnsFromServiceId(props.serviceId));

const handleAddOnToggled = (addOnId: AddOnId, newValue: boolean) => {
  if (!newValue) {
    bookingStore.removeAddOnFromGuest(props.guestNumber, props.serviceId, addOnId);
    return;
  }

  bookingStore.addAddOnToGuest(props.guestNumber, props.serviceId, addOnId);
};

const guestHasAddOn = (addOnId: AddOnId): boolean => {
  return Boolean(bookingStore.servicesByGuest[props.guestNumber - 1]?.get(props.serviceId)?.addOnIds.has(addOnId));
};

const handleLearnMoreClicked = (addOnId: AddOnId) => {
  router.push(`/locations/${props.locationId}/info/services/${props.serviceId}/add-ons/${addOnId}`);
};
</script>

<template>
  <h2 v-if="serviceType" class="flex flex-col justify-center my-5">
    <TextHeader1 v-if="bookingStore.hasMultipleGuests" class="text-center">{{ t('general.guestLabel', { guestNumber }) }}:</TextHeader1>
    <TextHeader1 class="text-center">{{ ['manicure', 'maniPediManicure'].includes(serviceType) ? t('addOns.headerManicure') : t('addOns.headerPedicure') }}</TextHeader1>
  </h2>

  <div class="flex flex-col md:flex-row md:flex-wrap justify-between gap-5 px-5">
    <div v-for="addOn in addOns" class="w-full md:w-[calc(50%-0.625rem)] shrink-0 grow-0">
      <ServiceCard @learnMore="() => handleLearnMoreClicked(addOn.id)" :modelValue="guestHasAddOn(addOn.id)"
        @update:modelValue="(newValue: boolean) => handleAddOnToggled(addOn.id, newValue)"
        :image="ServiceCardImage"
        :header="addOn.name"
        :bodyText="addOn.shortDescription"
        :duration="addOn.typicalDurationMins"
        :priceCents="addOn.priceCents" />
    </div>
  </div>
</template>


<script setup lang="ts">
import TextBody2 from "@/src/components/TextBody2.vue";
import TextListItem1 from "@/src/components/TextListItem1.vue";
import { type ServiceSummary, isSingleServiceSummary } from "@/src/stores/bookingStore";
import { useI18n } from "vue-i18n";

defineProps<{
  service: ServiceSummary;
}>();

const { t } = useI18n();
</script>

<template>
  <template v-if="isSingleServiceSummary(service)">
    <TextBody2 class="block">{{ service.serviceName }}</TextBody2>
    <TextBody2 class="block">{{ t('guestsGroupPackageServices.includesRemoval') }}</TextBody2>
    <template v-for="addOn in service.addOnNames">
      <TextListItem1 class="block">{{ addOn }}</TextListItem1>
    </template>
  </template>
</template>

import type { Branded } from "@/src/lib/branded";
import { type CountryCode, type PhoneNumber as PhoneNumberExt, parsePhoneNumberWithError } from "libphonenumber-js";

export type PhoneNumberCallingCode = Branded<string, "PhoneNumberCallingCode">;
export type PhoneNumberCountryCode = CountryCode;
export type PhoneNumberE164 = Branded<string, "PhoneNumberE164">;
export type PhoneNumberNational = Branded<string, "PhoneNumberNational">;

export class PhoneNumberMalformedError extends Error {}
export class PhoneNumberInvalidError extends Error {}

export class PhoneNumber {
  readonly countryCode: PhoneNumberCountryCode | null;
  readonly countryCallingCode: PhoneNumberCallingCode;
  readonly e164Number: PhoneNumberE164;
  readonly nationalNumber: PhoneNumberNational;

  constructor(rawPhoneNumber: string, region: CountryCode) {
    let phoneNumber: PhoneNumberExt;

    try {
      phoneNumber = parsePhoneNumberWithError(rawPhoneNumber, {
        defaultCountry: region,
        extract: false,
      });
    } catch (e) {
      throw new PhoneNumberMalformedError(`The phone number '${rawPhoneNumber}' is not in the right format`, {
        cause: e,
      });
    }

    if (!phoneNumber.isValid()) {
      throw new PhoneNumberInvalidError(`The phone number '${rawPhoneNumber}' is not a valid phone number`);
    }

    this.countryCode = phoneNumber.country || (null as PhoneNumberCountryCode | null);
    this.countryCallingCode = phoneNumber.countryCallingCode as string as PhoneNumberCallingCode;
    this.e164Number = phoneNumber.number as string as PhoneNumberE164;
    this.nationalNumber = phoneNumber.nationalNumber as string as PhoneNumberNational;
  }

  equals(other: unknown): boolean {
    return other instanceof PhoneNumber && this.e164Number === other.e164Number;
  }

  toString(): string {
    return this.e164Number;
  }

  valueOf() {
    return this.e164Number;
  }
}

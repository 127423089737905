<script setup lang="ts">
import RewardsInfo from "@/src/components/RewardsInfo.vue";
import { useUserStore } from "@/src/stores/userStore";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

const userStore = useUserStore();
const router = useRouter();

const { userLoyaltyPoints } = storeToRefs(userStore);

const handleBack = () => {
  router.back();
};
</script>

<template>
  <RewardsInfo
    @back="handleBack"
    :pointsBalance="userLoyaltyPoints ? userLoyaltyPoints.pointsBalance : 0 "
    :pointsValueCents="userLoyaltyPoints ? userLoyaltyPoints.pointsValueCents : 0"
  />
</template>

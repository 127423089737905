<script setup lang="ts">
import ButtonInScreenPrimary from "@/src/components/ButtonInScreenPrimary.vue";
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import GuestGroupPackageSummary from "@/src/components/GuestGroupPackageSummary.vue";
import Icon from "@/src/components/Icon.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import TextLink from "@/src/components/TextLink.vue";
import { serviceToSummary } from "@/src/lib/summaryHelpers";
import type { ServiceByGuest, ServicesByLocation } from "@/src/stores/bookingStore";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  servicesByLocation: ServicesByLocation;
  servicesByGuest: ServiceByGuest[];
}>();

const servicesByGuestArray = computed(() => {
  return Array.from(props.servicesByGuest.values());
});

const emit = defineEmits<{
  copy: [];
  edit: [guestNumberToEdit: number];
}>();

const handleEditClicked = (guestNumberToEdit: number) => {
  emit("edit", guestNumberToEdit);
};
</script>

<template>
  <div v-for="(servicesForGuest, guestIndex) in servicesByGuestArray" >
    <div class="m-5 mb-10 flex flex-col">
      <div v-for="[id, service] in Array.from(servicesForGuest.entries())">
        <div v-if="serviceToSummary(servicesByLocation, id, service).addOnNames.length === 0" >
          <TextHeader3>{{ t('general.guestLabel', { guestNumber: guestIndex + 1 }) }}</TextHeader3>
          <div class="flex justify-between" >
            <div class="flex flex-col items-start">
              <GuestGroupPackageSummary :service="serviceToSummary(servicesByLocation, id, service)"/>
            </div>
            <ButtonInScreenPrimary @click="handleEditClicked(guestIndex + 1)" class="max-w-[175px] md:max-w-[225px] max-h-[50px]">
              <div class="flex justify-between">
                <TextBody1>{{ t("guestsGroupPackageServices.chooseAddOns") }}</TextBody1>
                <Icon :icon="faAngleRight" size="lg"/>
              </div>
            </ButtonInScreenPrimary>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-between">
            <TextHeader3>{{ t('general.guestLabel', { guestNumber: guestIndex + 1 }) }}</TextHeader3>
            <TextLink @click="handleEditClicked(guestIndex + 1)" >
              <TextBody1>{{ t("guestsGroupPackageServices.edit") }}</TextBody1>
            </TextLink>
          </div>
          <div class="flex flex-col items-start">
            <GuestGroupPackageSummary :service="serviceToSummary(servicesByLocation, id, service)"/>
          </div>
        </div>
        <div class="flex justify-center">
          <ButtonInScreenTertiary class="max-w-[250px] mt-5" v-if="guestIndex === 0 && serviceToSummary(servicesByLocation, id, service).addOnNames.length != 0" @click="$emit('copy')">
            <div class="flex justify-between items-center">
              <div>
                <TextBody1>{{ t("guestsGroupPackageServices.copy") }}</TextBody1>
              </div>
              <Icon :icon="faAngleRight" size="lg"/>
            </div>
          </ButtonInScreenTertiary>
        </div>
      </div>
    </div>
    <SectionDivider />
  </div>
</template>

<script setup lang="ts">
import ButtonInScreenPrimary from "@/src/components/ButtonInScreenPrimary.vue";
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import ClosePopup from "@/src/components/ClosePopup.vue";
import Icon from "@/src/components/Icon.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import type { PaymentAccount } from "@/src/lib/townhouseApiClient";
import { useBookingStore } from "@/src/stores/bookingStore";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const bookingStore = useBookingStore();

const { paymentAccountChosen, paymentAccounts } = storeToRefs(bookingStore);

const chosenPaymentAccount = ref<PaymentAccount | null>(paymentAccountChosen.value);

const emit = defineEmits<{
  paymentAccountChosenNextClick: [];
  cancelled: [];
  paymentAccountAddClicked: [];
}>();

const handlePaymentAccountSelected = (paymentAccount: PaymentAccount) => {
  chosenPaymentAccount.value = paymentAccount;
};

const handlePaymentAccountChosenNextClick = () => {
  if (chosenPaymentAccount.value) {
    bookingStore.setChosenPaymentAccount(chosenPaymentAccount.value);
  }
  emit("paymentAccountChosenNextClick");
};
</script>
<template>
  <div role="dialog" aria-modal="true" @click="$emit('cancelled')" @scroll.prevent @wheel.prevent @touchmove.prevent class="fixed inset-0 z-20 w-screen h-screen bg-popup-background p-10 flex justify-center items-start">
    <div class="w-[600px] bg-white flex flex-col justify-center items-center relative">
      <ClosePopup @click="$emit('cancelled')"/>
      <TextHeader1 class="p-10 text-center">{{ t("popup.selectPaymentAccount.header") }}</TextHeader1>
      <SectionDivider />
      <div class="flex flex-col p-5 items-center">
        <div class="flex flex-col justify-between mt-5 min-w-[300px] max-w-[600px]">
          <ButtonInScreenTertiary class="mx-auto max-w-[600px] mb-10" @click="$emit('paymentAccountAddClicked');">
            <div class="flex justify-between items-center">
              <div>
                <Icon class="mr-5" :icon="faCreditCard" size="lg"/>
                <TextBody1>{{ t("popup.selectPaymentAccount.addCard") }}</TextBody1>
              </div>
              <Icon class="mr-5" :icon="faAngleRight" size="lg"/>
            </div>
          </ButtonInScreenTertiary>
          <ButtonInScreenPrimary v-for="paymentAccount in paymentAccounts" :selected="paymentAccount.id === chosenPaymentAccount?.id" @click="() => handlePaymentAccountSelected(paymentAccount)" class="flex justify-start items-center w-full max-w-[600px] mb-5">
          <!-- FIXME: Show correct icon based on payment method -->
          <Icon class="mr-5" :icon="faCreditCard" size="lg"/>
          <TextBody1>{{ t("popup.selectPaymentAccount.card") }} {{ paymentAccount.lastFour }}</TextBody1>
        </ButtonInScreenPrimary>
      </div>
      <ButtonPrimary @click="handlePaymentAccountChosenNextClick" class="basis-0 grow-0 max-w-[125px] mt-20 whitespace-nowrap">
        {{ t("popup.selectPaymentAccount.next") }}
      </ButtonPrimary>
    </div>
  </div>
</div>
</template>

<script setup lang="ts">
import ServiceCardImage from "@/src/assets/service-card-placeholder.png";
import ServiceInfo from "@/src/components/ServiceInfo.vue";
import type { LocationId, RemovalId, ServiceId } from "@/src/lib/townhouseApiClient";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import _ from "lodash";
import { computed } from "vue";
import { useRouter } from "vue-router";

const bookingStore = useBookingStore();
const router = useRouter();

const props = defineProps<{
  locationId: LocationId;
  guestNumber: GuestNumber;
  serviceId: ServiceId;
  removalId: RemovalId;
}>();

const removalInfo = computed(() => {
  return bookingStore.servicesByLocation?.get(props.serviceId)?.removals.get(props.removalId);
});

const handleBack = () => {
  router.back();
};
</script>

<template>
  <ServiceInfo v-if="removalInfo" @back="handleBack"
    :image="ServiceCardImage"
    :serviceName="removalInfo.name"
    :textBody="removalInfo.longDescription"
    :durationMins="removalInfo.typicalDurationMins"
    :priceCents="removalInfo.priceCents" />
</template>

<script setup lang="ts">
import type { CallbackEvent } from "@/src/lib/callbackEvent";
import { onMounted } from "vue";

onMounted(() => {
  window.parent.postMessage({
    type: "callback",
    params: location.search,
  } as CallbackEvent);
});
</script>

<template>
</template>

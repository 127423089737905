<script setup lang="ts">
import ServiceCardImage from "@/src/assets/service-card-placeholder.png";
import ServiceCard from "@/src/components/ServiceCard.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import type { LocationId, RemovalId, ServiceId } from "@/src/lib/townhouseApiClient";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const { t } = useI18n();
const bookingStore = useBookingStore();
const router = useRouter();

const props = defineProps<{
  locationId: LocationId;
  guestNumber: GuestNumber;
  serviceId: ServiceId;
}>();

const serviceType = computed(() => bookingStore.servicesByLocation?.get(props.serviceId)?.serviceType);
const removals = computed(() => bookingStore.getRemovalsFromServiceId(props.serviceId));

const handleRemovalToggled = (removalId: RemovalId, newValue: boolean) => {
  if (!newValue) {
    bookingStore.removeRemovalFromGuest(props.guestNumber, props.serviceId, removalId);
    return;
  }

  bookingStore.addRemovalToGuest(props.guestNumber, props.serviceId, removalId);
};

const guestHasRemoval = (removalId: RemovalId): boolean => {
  return Boolean(bookingStore.servicesByGuest[props.guestNumber - 1]?.get(props.serviceId)?.removalIds.has(removalId));
};

const handleLearnMoreClicked = (removalId: RemovalId) => {
  router.push(`/locations/${props.locationId}/info/services/${props.serviceId}/removals/${removalId}`);
};
</script>

<template>
  <h2 v-if="serviceType" class="flex flex-col justify-center my-5">
    <TextHeader1 v-if="bookingStore.hasMultipleGuests" class="text-center">{{ t('general.guestLabel', { guestNumber }) }}: </TextHeader1>
    <TextHeader1 class="text-center">{{ ['manicure', 'maniPediManicure'].includes(serviceType) ? t('removals.headerManicure') : t('removals.headerPedicure') }}</TextHeader1>
  </h2>

  <div class="flex flex-col md:flex-row md:flex-wrap justify-between gap-5 px-5">
    <div v-for="removal in removals" class="w-full md:w-[calc(50%-0.625rem)] shrink-0 grow-0">
      <ServiceCard @learnMore="() => handleLearnMoreClicked(removal.id)" :modelValue="guestHasRemoval(removal.id)"
        @update:modelValue="(newValue: boolean) => handleRemovalToggled(removal.id, newValue)"
        :image="ServiceCardImage"
        :header="removal.name"
        :bodyText="removal.shortDescription"
        :duration="removal.typicalDurationMins"
        :priceCents="removal.priceCents" />
    </div>
  </div>
</template>

<script setup lang="ts">
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBodyError from "@/src/components/TextBodyError.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const { isDateOfBirth = false } = defineProps<{
  label: string;
  required?: boolean;
  isDateOfBirth?: boolean;
  error?: string;
  errorInDay?: boolean;
  errorInMonth?: boolean;
  errorInYear?: boolean;
}>();

const dayModel = defineModel("day");
const monthModel = defineModel("month");
const yearModel = defineModel("year");
</script>

<template>
  <div>
    <fieldset role="group">
      <legend>
        <TextHeader3 class="block">
          {{ label }}{{ !required ? ` ${t("input.optionalLabel")}` : "" }}
        </TextHeader3>
      </legend>
      <div class="flex gap-5">
        <label class="basis-1/4">
          <TextBody1 class="block">Day</TextBody1>
          <input
            :class="`w-full rounded-none border-2 ${ errorInDay ? 'border-error' : 'border-input-border' } text-base p-5 focus:outline-none focus:border-2 focus:border-input-focus-outline`"
            v-model="dayModel"
            :autocomplete="isDateOfBirth ? 'bday-day' : undefined"
            :required="required"
            placeholder="DD"
            inputmode="numeric"
            :aria-invalid="Boolean(errorInDay)"
            :aria-errormessage="errorInDay ? error : undefined"
          />
        </label>
        <label class="basis-1/4">
          <TextBody1 class="block">Month</TextBody1>
          <input
            :class="`w-full rounded-none border-2 ${ errorInMonth ? 'border-error' : 'border-input-border' } text-base p-5 focus:outline-none focus:border-2 focus:border-input-focus-outline`"
            v-model="monthModel"
            :autocomplete="isDateOfBirth ? 'bday-month' : undefined"
            :required="required"
            placeholder="MM"
            inputmode="numeric"
            :aria-invalid="Boolean(errorInMonth)"
            :aria-errormessage="errorInMonth ? error : undefined"
          />
        </label>
        <label class="basis-1/2">
          <TextBody1 class="block">Year</TextBody1>
          <input
            :class="`w-full rounded-none border-2 ${ errorInYear ? 'border-error' : 'border-input-border' } text-base p-5 focus:outline-none focus:border-2 focus:border-input-focus-outline`"
            v-model="yearModel"
            :autocomplete="isDateOfBirth ? 'bday-year' : undefined"
            :required="required"
            placeholder="YYYY"
            inputmode="numeric"
            :aria-invalid="Boolean(errorInYear)"
            :aria-errormessage="errorInYear ? error : undefined"
          />
        </label>
      </div>
    </fieldset>
    <TextBodyError v-if="error" class="block text-xs mt-1">
      {{ error }}
    </TextBodyError>
  </div>
</template>

import type { BookingProgressStage, BookingStage } from "@/src/config/stages";
import type { AddOnId, LocationId, RemovalId, ServiceId } from "@/src/lib/townhouseApiClient";
import AccountDetailsPage from "@/src/pages/AccountDetailsPage.vue";
import AccountPage from "@/src/pages/AccountPage.vue";
import AccountPaymentAccountsPage from "@/src/pages/AccountPaymentAccountsPage.vue";
import AddOnInfoPage from "@/src/pages/AddOnInfoPage.vue";
import AddOnsPage from "@/src/pages/AddOnsPage.vue";
import CallbackPage from "@/src/pages/CallbackPage.vue";
import CompletePage from "@/src/pages/CompletePage.vue";
import ConfirmPage from "@/src/pages/ConfirmPage.vue";
import DateTimePage from "@/src/pages/DateTimePage.vue";
import GroupBookingsPage from "@/src/pages/GroupPackagesPage.vue";
import GuestsPage from "@/src/pages/GuestsPage.vue";
import LocationsPage from "@/src/pages/LocationsPage.vue";
import NeedsRemovalPage from "@/src/pages/NeedsRemovalPage.vue";
import NumberOfGuestsPage from "@/src/pages/NumberOfGuestsPage.vue";
import RemovalInfoPage from "@/src/pages/RemovalInfoPage.vue";
import RemovalsPage from "@/src/pages/RemovalsPage.vue";
import RewardsPage from "@/src/pages/RewardsPage.vue";
import ServiceInfoPage from "@/src/pages/ServiceInfoPage.vue";
import ServicesPage from "@/src/pages/ServicesPage.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import { useUserStore } from "@/src/stores/userStore";
import _ from "lodash";
import { type RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    beforeEnter: async () => {
      const authStore = useAuthStore();

      await authStore.verifySession();
    },
    children: [
      {
        path: "",
        beforeEnter: () => {
          const bookingStore = useBookingStore();

          if (bookingStore.bookingComplete) {
            bookingStore.$reset();
          }
        },
        component: LocationsPage,
        props: true,
        meta: {
          stage: "location" as BookingStage,
          progressStage: "location" as BookingProgressStage,
        },
      },
      {
        path: "account",
        beforeEnter: async () => {
          const authStore = useAuthStore();
          const userStore = useUserStore();

          if (!authStore.isLoggedIn) {
            return "/";
          }

          await userStore.fetchUser();
        },
        meta: {
          stage: "account" as BookingStage,
        },
        children: [
          {
            path: "",
            component: AccountPage,
          },
          {
            path: "details",
            component: AccountDetailsPage,
          },
          {
            path: "payment_accounts",
            beforeEnter: async () => {
              const bookingStore = useBookingStore();
              await bookingStore.fetchPaymentAccounts();
            },
            component: AccountPaymentAccountsPage,
          },
          {
            path: "loyalty_points",
            beforeEnter: async () => {
              const userStore = useUserStore();
              await userStore.fetchUserLoyaltyPoints();
            },
            component: RewardsPage,
          },
        ],
      },
      {
        path: "locations/:locationId",
        beforeEnter: async (to) => {
          const bookingStore = useBookingStore();

          if (bookingStore.bookingComplete) {
            bookingStore.$reset();
            return "/";
          }

          bookingStore.setLocation(to.params["locationId"] as LocationId);

          await bookingStore.fetchLocationInfo();
          await bookingStore.fetchServices();
        },
        children: [
          {
            path: "",
            component: NumberOfGuestsPage,
            beforeEnter: () => {
              const bookingStore = useBookingStore();

              if (bookingStore.bookingComplete) {
                bookingStore.$reset();
                return "/";
              }
            },
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
            }),
            meta: {
              stage: "numberOfGuests" as BookingStage,
              progressStage: "guests" as BookingProgressStage,
            },
          },
          {
            path: "guests",
            component: GuestsPage,
            beforeEnter: (to) => {
              const bookingStore = useBookingStore();

              if (bookingStore.bookingComplete) {
                bookingStore.$reset();
                return "/";
              }

              if (bookingStore.numberOfGuests === 1) {
                return `/locations/${to.params["locationId"]}/guests/1/services`;
              }

              if (bookingStore.numberOfGuests < 2) {
                return `/locations/${to.params["locationId"]}`;
              }
            },
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
            }),
            meta: {
              stage: "guests" as BookingStage,
              progressStage: "guests" as BookingProgressStage,
            },
          },
          {
            path: "group-packages",
            component: GroupBookingsPage,
            beforeEnter: (to) => {
              const bookingStore = useBookingStore();

              if (bookingStore.bookingComplete) {
                bookingStore.$reset();
                return "/";
              }

              if (bookingStore.numberOfGuests < 3 || bookingStore.numberOfGuests > 6) {
                return `/locations/${to.params["locationId"]}`;
              }
            },
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
            }),
            meta: {
              stage: "groupPackages" as BookingStage,
              progressStage: "guests" as BookingProgressStage,
            },
          },
          {
            path: "guests/:guestNumber",
            beforeEnter: (to) => {
              const bookingStore = useBookingStore();

              if (bookingStore.bookingComplete) {
                bookingStore.$reset();
                return "/";
              }

              const guestNumber = Number.parseInt(to.params["guestNumber"].toString(), 10);
              if (
                !(bookingStore.hasSelectedNumberOfGuests && _.inRange(guestNumber, 0, bookingStore.numberOfGuests + 1))
              ) {
                return "/";
              }
            },
            children: [
              {
                path: "services",
                beforeEnter: (to) => {
                  const bookingStore = useBookingStore();

                  if (bookingStore.isGroupPackageChosen() && !to.fullPath.includes("add-ons")) {
                    return `/locations/${to.params["locationId"]}/guests`;
                  }
                },
                component: ServicesPage,
                props: (route) => ({
                  locationId: route.params["locationId"] as LocationId,
                  guestNumber: Number.parseInt(route.params["guestNumber"].toString(), 10) as GuestNumber,
                }),
                meta: {
                  stage: "services" as BookingStage,
                  progressStage: "services" as BookingProgressStage,
                },
              },
              {
                path: "services/:serviceId",
                beforeEnter: (to) => {
                  const bookingStore = useBookingStore();

                  if (bookingStore.bookingComplete) {
                    bookingStore.$reset();
                    return "/";
                  }

                  if (bookingStore.isGroupPackageChosen() && !to.fullPath.includes("add-ons")) {
                    return `/locations/${to.params["locationId"]}/guests`;
                  }

                  const guestNumber = Number.parseInt(to.params["guestNumber"].toString(), 10);
                  if (!bookingStore.servicesByGuest[guestNumber - 1]?.get(to.params["serviceId"] as ServiceId)) {
                    return `/locations/${to.params["locationId"]}/guests/1/services`;
                  }
                },
                children: [
                  {
                    path: "add-ons",
                    component: AddOnsPage,
                    beforeEnter: (to) => {
                      const bookingStore = useBookingStore();

                      if (bookingStore.getAddOnsFromServiceId(to.params["serviceId"] as ServiceId).length === 0) {
                        return `/locations/${to.params["locationId"]}/guests/${to.params["guestNumber"]}/services/${to.params["serviceId"]}/needs-removal`;
                      }
                    },
                    props: (route) => ({
                      locationId: route.params["locationId"] as LocationId,
                      guestNumber: Number.parseInt(route.params["guestNumber"].toString(), 10) as GuestNumber,
                      serviceId: route.params["serviceId"] as ServiceId,
                    }),
                    meta: {
                      stage: "addOns" as BookingStage,
                      progressStage: "services" as BookingProgressStage,
                    },
                  },
                  {
                    path: "needs-removal",
                    component: NeedsRemovalPage,
                    props: (route) => ({
                      locationId: route.params["locationId"] as LocationId,
                      guestNumber: Number.parseInt(route.params["guestNumber"].toString(), 10) as GuestNumber,
                      serviceId: route.params["serviceId"] as ServiceId,
                    }),
                    meta: {
                      stage: "needsRemoval" as BookingStage,
                      progressStage: "services" as BookingProgressStage,
                    },
                  },
                  {
                    path: "removals",
                    component: RemovalsPage,
                    props: (route) => ({
                      locationId: route.params["locationId"] as LocationId,
                      guestNumber: Number.parseInt(route.params["guestNumber"].toString(), 10) as GuestNumber,
                      serviceId: route.params["serviceId"] as ServiceId,
                    }),
                    meta: {
                      stage: "removals" as BookingStage,
                      progressStage: "services" as BookingProgressStage,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "date-time",
            beforeEnter: (to) => {
              const bookingStore = useBookingStore();

              if (bookingStore.bookingComplete) {
                bookingStore.$reset();
                return "/";
              }

              if (!bookingStore.hasPopulatedAllGuests) {
                if (bookingStore.hasMultipleGuests) {
                  return `/locations/${to.params["locationId"]}/guests`;
                }
                return `/locations/${to.params["locationId"]}/guests/1/services`;
              }
            },
            component: DateTimePage,
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
            }),
            meta: {
              stage: "dateTime" as BookingStage,
              progressStage: "dateTime" as BookingProgressStage,
            },
          },
          {
            path: "confirm",
            beforeEnter: async (to) => {
              const bookingStore = useBookingStore();

              if (bookingStore.bookingComplete) {
                bookingStore.$reset();
                return "/";
              }

              if (!bookingStore.bookingReservationId) {
                return `/locations/${to.params["locationId"]}/date-time`;
              }

              await bookingStore.fetchPaymentAccounts();
            },
            component: ConfirmPage,
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
            }),
            meta: {
              stage: "confirm" as BookingStage,
              progressStage: "confirm" as BookingProgressStage,
            },
          },
          {
            path: "complete",
            beforeEnter: () => {
              const bookingStore = useBookingStore();

              if (!bookingStore.bookingComplete) {
                return "/";
              }
            },
            component: CompletePage,
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
            }),
            meta: {
              stage: "complete" as BookingStage,
              progressStage: "complete" as BookingProgressStage,
            },
          },
          {
            path: "info/services/:serviceId",
            component: ServiceInfoPage,
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
              serviceId: route.params["serviceId"] as ServiceId,
            }),
            meta: {
              stage: "info" as BookingStage,
              progressStage: "services" as BookingProgressStage,
            },
          },
          {
            path: "info/services/:serviceId/add-ons/:addOnId",
            component: AddOnInfoPage,
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
              serviceId: route.params["serviceId"] as ServiceId,
              addOnId: route.params["addOnId"] as AddOnId,
            }),
            meta: {
              stage: "info" as BookingStage,
              progressStage: "services" as BookingProgressStage,
            },
          },
          {
            path: "info/services/:serviceId/removals/:removalId",
            component: RemovalInfoPage,
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
              serviceId: route.params["serviceId"] as ServiceId,
              removalId: route.params["removalId"] as RemovalId,
            }),
            meta: {
              stage: "info" as BookingStage,
              progressStage: "services" as BookingProgressStage,
            },
          },
        ],
      },
      {
        path: "callback",
        component: CallbackPage,
        meta: {
          callback: true,
        },
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

<script setup lang="ts">
import ButtonBinary from "@/src/components/ButtonBinary.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import type { LocationId, ServiceId } from "@/src/lib/townhouseApiClient";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const bookingStore = useBookingStore();

const props = defineProps<{
  locationId: LocationId;
  guestNumber: GuestNumber;
  serviceId: ServiceId;
}>();

const modelValue = computed(() => bookingStore.getNeedsRemovalForGuest(props.guestNumber, props.serviceId));
const serviceType = computed(() => bookingStore.servicesByLocation?.get(props.serviceId)?.serviceType);

const handleNeedsRemovalToggled = (newValue: boolean | null) => {
  if (newValue != null) {
    bookingStore.setNeedsRemovalForGuest(props.guestNumber, props.serviceId, newValue);
  }
};
</script>

<template>
  <h2 v-if="serviceType" class="flex flex-col justify-center mt-5 mb-36">
    <TextHeader1 v-if="bookingStore.hasMultipleGuests" class="text-center">{{ t('general.guestLabel', { guestNumber }) }}: </TextHeader1>
    <TextHeader1 class="text-center">{{ ['manicure', 'maniPediManicure'].includes(serviceType) ? t('needsRemoval.headerManicure') : t('needsRemoval.headerPedicure') }}</TextHeader1>
  </h2>

  <div class="px-5">
    <ButtonBinary
      labelOne="Yes"
      :valueOne="true"
      labelTwo="No"
      :valueTwo="false"
      :modelValue="modelValue"
      @update:modelValue="(newValue) => handleNeedsRemovalToggled(newValue)">
    </ButtonBinary>
  </div>
</template>

<script setup lang="ts">
import ButtonInScreenPrimary from "@/src/components/ButtonInScreenPrimary.vue";
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import ClosePopup from "@/src/components/ClosePopup.vue";
import Icon from "@/src/components/Icon.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import type { Employee } from "@/src/lib/townhouseApiClient";
import { useBookingStore } from "@/src/stores/bookingStore";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const bookingStore = useBookingStore();

const { employeeChosen, availableEmployees } = storeToRefs(bookingStore);

const chosenEmployee = ref<Employee | null>(employeeChosen.value);

const emit = defineEmits<{
  employeeChosenNextClick: [];
  cancelled: [];
}>();

const handleEmployeeSelected = (employee: Employee | null) => {
  chosenEmployee.value = employee;
};

const handleEmployeeChosenNextClick = () => {
  // We do not await this promise so that the popup doesn't sit stalling whilst the available times are refetched
  // The loading handling on the date/times screen takes care of giving the user feedback that stuff is loading already
  bookingStore.setChosenEmployee(chosenEmployee.value);
  emit("employeeChosenNextClick");
};
</script>
<template>
  <div role="dialog" aria-modal="true" @click="$emit('cancelled')" @scroll.prevent @wheel.prevent @touchmove.prevent class="fixed inset-0 z-20 w-screen h-screen bg-popup-background p-10 flex justify-center items-start">
    <div class="w-[600px] bg-white flex flex-col justify-center items-center relative">
      <ClosePopup @click="$emit('cancelled')"/>
      <TextHeader1 class="p-10 px-12 text-center">{{ t("popup.selectEmployee.header") }}</TextHeader1>
      <SectionDivider />
      <div class="flex flex-col p-5 items-center">
        <div class="flex flex-col justify-between mt-5 min-w-[300px] max-w-[600px]">
          <ButtonInScreenPrimary :selected="chosenEmployee === null" @click="() => handleEmployeeSelected(null)" class="flex justify-start items-center w-full max-w-[600px] mb-5">
            <Icon class="mr-5" :icon="faUser" size="lg"/>
            <TextBody1>{{ t('popup.selectEmployee.any') }}</TextBody1>
          </ButtonInScreenPrimary>
          <ButtonInScreenPrimary v-for="availableEmployee in availableEmployees" :selected="availableEmployee.zenotiEmployeeId === chosenEmployee?.zenotiEmployeeId" @click="() => handleEmployeeSelected(availableEmployee)" class="flex justify-start items-center w-full max-w-[600px] mb-5">
            <Icon class="mr-5" :icon="faUser" size="lg"/>
            <TextBody1>{{ availableEmployee.firstName }}</TextBody1>
        </ButtonInScreenPrimary>
      </div>
      <ButtonPrimary @click="handleEmployeeChosenNextClick" class="basis-0 grow-0 max-w-[250px] mt-20 whitespace-nowrap">
        {{ t("popup.selectEmployee.next") }}
      </ButtonPrimary>
    </div>
  </div>
</div>
</template>

<script setup lang="ts">
import ButtonInScreenPrimary from "@/src/components/ButtonInScreenPrimary.vue";
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import GuestServiceSummary from "@/src/components/GuestServiceSummary.vue";
import Icon from "@/src/components/Icon.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import TextLink from "@/src/components/TextLink.vue";
import { serviceToSummary } from "@/src/lib/summaryHelpers";
import type { ServiceByGuest, ServicesByLocation } from "@/src/stores/bookingStore";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  servicesByLocation: ServicesByLocation;
  servicesByGuest: ServiceByGuest[];
}>();

const emit = defineEmits<{
  copy: [];
  edit: [guestNumberToEdit: number];
}>();

const handleEditClicked = (guestNumberToEdit: number) => {
  emit("edit", guestNumberToEdit);
};
</script>

<template>
  <div v-for="(servicesForGuest, guestIndex) in servicesByGuest" >
    <div v-if="servicesForGuest.size === 0" class="m-5 mb-10 flex justify-between">
      <div class="flex flex-col items-start">
        <TextHeader3>{{ t('general.guestLabel', { guestNumber: guestIndex + 1 }) }}</TextHeader3>
        <TextBody1>{{ t("guestsMultipleServices.noServices") }}</TextBody1>
      </div>
      <div>
        <ButtonInScreenPrimary @click="handleEditClicked(guestIndex + 1)" class="min-w-[175px] max-w-[200px]">
          <div class="flex justify-between">
            <TextBody1>{{ t("guestsMultipleServices.addServices") }}</TextBody1>
            <Icon :icon="faAngleRight" size="lg"/>
          </div>
        </ButtonInScreenPrimary>
      </div>
    </div>
    <div v-else class="m-5 mb-10 flex flex-col">
      <div class="flex justify-between">
        <TextHeader3>{{ t('general.guestLabel', { guestNumber: guestIndex + 1 }) }}</TextHeader3>
        <TextLink @click="handleEditClicked(guestIndex + 1)" >
          <TextBody1>{{ t("guestsMultipleServices.edit") }}</TextBody1>
        </TextLink>
      </div>
      <div v-for="[id, service] in Array.from(servicesForGuest.entries())" class="flex flex-col items-start">
        <GuestServiceSummary :services="[[serviceToSummary(servicesByLocation, id, service)]]"/>
      </div>
      <div class="flex justify-center">
        <ButtonInScreenTertiary class="max-w-[250px] mt-5" v-if="guestIndex === 0" @click="$emit('copy')">
          <div class="flex justify-between items-center">
            <div>
              <TextBody1>{{ t("guestsMultipleServices.copy") }}</TextBody1>
            </div>
            <Icon :icon="faAngleRight" size="lg"/>
          </div>
        </ButtonInScreenTertiary>
      </div>
    </div>
    <SectionDivider />
  </div>
</template>
